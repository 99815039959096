import React from 'react';

import { FeedItemActivityType } from '@wix/social-groups-serverless/dist/feed/types';

import { RichContentActivity } from './RichContentActivity';
import { CoverImageChanged } from './CoverImageChanged';
import { DescriptionChanged } from './DescriptionChanged';

import { type IActivityContentProps } from './types';

export function ActivityContent(props: IActivityContentProps) {
  const { activity } = props.item;

  if (!activity) {
    return null;
  }

  switch (activity.activityType) {
    case FeedItemActivityType.ABOUT_GROUP_CHANGED:
      return <DescriptionChanged {...props} />;

    case FeedItemActivityType.GROUP_COVER_CHANGED:
      return <CoverImageChanged {...props} />;

    case FeedItemActivityType.DRAFT_JS:
    case FeedItemActivityType.RICH_CONTENT:
      return <RichContentActivity {...props} />;

    default:
      return null;
  }
}

ActivityContent.displayName = 'ActivityContent';
