import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

import { EMediaLayout } from '../constants';

export const settings = {};

export const styles = {
  mediaImagesPerRow: createStylesParam('mediaImagesPerRow', {
    type: StyleParamType.Number,
    getDefaultValue: () => 5,
  }),

  overlayColor: createStylesParam('overlayColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  }),

  showMediaAuthorOverlay: createStylesParam('showMediaAuthorOverlay', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),

  mediaLayout: createStylesParam('mediaLayout', {
    type: StyleParamType.Number,
    getDefaultValue: () => EMediaLayout.Grid,
  }),
};
