export enum GroupAction {
  NOTIFICATION_SETTINGS = 'groups-web.group.actions.settings.notifications',
  GROUP_SETTINGS = 'groups-web.group.actions.settings.group',
  SHARE = 'groups-web.group.actions.share',
  // disable for now until it would be implemented
  // initial request https://wix.slack.com/archives/CDE9J122H/p1580934558016900
  // REPORT = 'groups-web.group.actions.report',
  LEAVE = 'groups-web.group.actions.leave',
  DELETE = 'groups-web.group.actions.delete',
}
