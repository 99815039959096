import { EditableImage } from './types';
import { Image } from '@wix/ambassador-social-groups-v2-group/types';

export function isFile(coverImage?: EditableImage): boolean {
  return coverImage?.image instanceof File;
}

export function getPreview(value?: EditableImage) {
  if (isFile(value)) {
    return URL.createObjectURL(value?.image as File);
  }

  return (value?.image as Image)?.mediaId as string | undefined;
}
