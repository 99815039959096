import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';

import type { IUsersJoinedActivity } from '@wix/social-groups-serverless/dist/feed/types';
import { selectGroupName, selectGroupSlugById } from 'store/selectors';

import { TextButton } from 'wui/TextButton';
import { InputDialog } from 'wui/InputDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { List } from 'wui/List';

import { Link } from '../../../Link';
import { FollowButton, ProfileListItem } from '../../../ProfileListItem';

import { IActivityDescriptionProps } from './types';

export function ManyUsersJoined(props: IActivityDescriptionProps) {
  const { item, full } = props;

  const data = item.activity?.data as IUsersJoinedActivity;
  const groupId = item.applicationContext?.contextId as string;
  const users = Object.values(data.users);

  const { t } = useTranslation();
  const slug = useSelector(selectGroupSlugById(groupId));
  const groupName = useSelector(selectGroupName(groupId));

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Trans
        i18nKey={
          full
            ? 'groups-web.activity-post.users.joined.with-group-name'
            : 'groups-web.activity-post.users.joined'
        }
        values={{
          groupName,
          N: data.userIds.length,
        }}
        components={[
          <span key={0} />,
          <TextButton key={1} variant="secondary" onClick={handleDialogOpen} />,
          <Link key={2} state="group" params={{ slug }} />,
        ]}
      />
      <InputDialog
        isOpen={isOpen}
        onClose={handleDialogClose}
        aria-labelledby="muj-dialog-title"
      >
        <DialogTitle
          id="muj-dialog-title"
          title={t('groups-web.users-joined-popup.title')}
        />
        <DialogContent>
          <List disablePadding>
            {users.map((user) => (
              <ProfileListItem
                disableGutters
                profile={user}
                key={user.memberId}
              >
                <FollowButton profile={user} feedItemId={item.feedItemId} />
              </ProfileListItem>
            ))}
          </List>
        </DialogContent>
      </InputDialog>
    </>
  );

  function handleDialogOpen() {
    setIsOpen(true);
  }

  function handleDialogClose() {
    setIsOpen(false);
  }
}

ManyUsersJoined.displayName = 'ManyUsersJoined';
