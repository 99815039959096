import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

export const settings = {};

const layoutSpacing = createStylesParam('layoutSpacing', {
  getDefaultValue: () => 20,
  type: StyleParamType.Number,
});

export const styles = {
  layoutSpacing,
  hSpacing: createStylesParam('hSpacing', {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(layoutSpacing) || 20,
  }),
  vSpacing: createStylesParam('vSpacing', {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(layoutSpacing) || 20,
  }),
  infoColumnWidth: createStylesParam('infoColumnWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 35,
  }),
};
