import parseColor from 'color';

import { getGroupsLogo } from './logo';

export async function buildCoverImage(
  color: string,
  width = 1920,
  height = 400,
  backgroundColor = '#ffffff',
  name = 'default-group-cover-image.png',
) {
  const LOGO_SIZE = height / 2;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  canvas.width = width;
  canvas.height = height;

  return new Promise<File>(async (resolve, reject) => {
    const img = new Image();
    const logo = await getGroupsLogo(parseColor(color).darken(1).string());

    img.src = URL.createObjectURL(logo);
    img.onerror = reject;
    img.onload = onImageLoad;

    function onImageLoad() {
      try {
        URL.revokeObjectURL(img.src);

        // Draw background
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, width, height);

        // Draw transparent color
        ctx.fillStyle = parseColor(color).rgb().string();
        ctx.fillRect(0, 0, width, height);

        // Draw Groups Application logo
        ctx.drawImage(
          img,
          width / 2 - LOGO_SIZE / 2,
          height / 2 - LOGO_SIZE / 2,
          LOGO_SIZE,
          LOGO_SIZE,
        );

        canvas.toBlob(function (blob) {
          if (!blob) {
            return reject();
          }

          return resolve(new File([blob!], name, { type: 'image/png' }));
        });
      } catch (error) {
        return reject(error);
      }
    }
  });
}
