import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { type IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';

import { Comment as CommentIcon } from '@wix/wix-ui-icons-common/on-stage';

import { FeedItemStat, type IFeedItemStatProps } from '../FeedItemStat';

export interface ICommentsStatProps extends Partial<IFeedItemStatProps> {
  item: IFeedItem;
}

export function CommentsStat(props: ICommentsStatProps) {
  const { item, ...rest } = props;

  const { t } = useTranslation();

  const count = item.comments?.total || item.latestComments?.total;

  return (
    <FeedItemStat
      count={count}
      icon={<CommentIcon />}
      label={t('groups-web.discussion.feed.total-comments_icu', {
        count,
      })}
      {...rest}
    />
  );
}

CommentsStat.displayName = 'CommentsStat';
