import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

export const settings = {};

export const styles = {
  showActivity: createStylesParam('showActivity', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
