import React, { useEffect, useImperativeHandle, useState } from 'react';
import cls from 'classnames';
import { type FormikErrors, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  Trans,
  useEnvironment,
  useTranslation,
  useBi,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import type { RichContent, RicosEditorType } from '@wix/ricos';
import { EditorEventsContext } from '@wix/ricos';
import { RichContentMigration } from '@wix/social-groups-serverless/dist/constants';
import {
  groupsModalButtonClick,
  groupsNotificationShownSrc111Evid510,
} from '@wix/bi-logger-groups-data/v2';
import { type groupsModalButtonClickParams } from '@wix/bi-logger-groups-data/v2/types';
import { omit } from 'lodash';

import type { IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';
import type { ITopic } from '@wix/social-groups-serverless/dist/topics/types';
import {
  selectCurrentUser,
  selectDidItemChange,
  selectDidItemCreate,
  selectGroupName,
  selectGroupSlugById,
  selectIsItemCreating,
  selectIsItemUpdating,
  selectGroupFeedSettings,
  selectHasAdminRole,
} from 'store/selectors';

import { useController } from 'common/context/controller';
import { useRichContent, serialize } from 'common/utils/ricos';
import { RicosEditor } from 'common/components/Ricos/loadable';
import { type IntentType } from 'common/components/Ricos/types';
import { RceEditorFallback } from 'common/components/Ricos/RceFallback';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Button } from 'wui/Button';
import { InputDialog } from 'wui/InputDialog';
import { ButtonGroup } from 'wui/ButtonGroup';
import { DialogFooter } from 'wui/DialogFooter';
import { DialogContent } from 'wui/DialogContent';
import { DialogMobileHeader } from 'wui/DialogMobileHeader';
import { CardHeader } from 'wui/CardHeader';
import { Avatar } from 'wui/Avatar';
import { TextButton } from 'wui/TextButton';
import { EditableText } from 'wui/EditableText';

import { GROUPS_APP_DEFINITION_ID } from '../../../constants';

import { Link } from '../Link';
import { ProfileLink } from '../ProfileLink';
import { TopicsSelect } from './TopicsSelect';

import classes from './FeedItemEditorDialog.scss';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  promoteGroup?: boolean;
  groupId?: string;
  item?: IFeedItem;
  forTopic?: ITopic;
  cancelLabel?: string;
  editorRef?: React.Ref<RicosEditorType>;
  biExtra?: groupsModalButtonClickParams;
  intent?: IntentType;
  draft?: RichContent;
  origin?: 'GroupFeed' | 'CentralFeed';
  onCancel?(): void;
  onClose(event?: React.SyntheticEvent, content?: RichContent): void;
}

interface IFeedItemForm {
  groupId: string;
  feedItemId?: string;
  topics: ITopic[];
  content?: RichContent;
  title?: string;
}
export function FeedItemEditorDialog(props: IProps) {
  const {
    item,
    groupId,
    forTopic,
    onClose,
    onCancel,
    className,
    cancelLabel,
    editorRef,
    promoteGroup,
    biExtra,
    intent,
    draft,
    origin = 'GroupFeed',
    ...rest
  } = props;

  const { t } = useTranslation();
  const { feed$ } = useController();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const bi = useBi();

  const $ricos = React.useRef<RicosEditorType>(null);

  const editor = React.useContext(EditorEventsContext);

  const feedItemId = item?.feedItemId;
  const isNewPost = !feedItemId;

  const user = useSelector(selectCurrentUser);
  const didCreate = useSelector(selectDidItemCreate);
  const isCreating = useSelector(selectIsItemCreating);
  const isUpdating = useSelector(selectIsItemUpdating(feedItemId));
  const didUpdate = useSelector(selectDidItemChange(feedItemId));
  const groupName = useSelector(selectGroupName(groupId as string));
  const slug = useSelector(selectGroupSlugById(groupId as string));
  const feedSettings = useSelector(selectGroupFeedSettings(groupId as string));
  const isAdmin = useSelector(selectHasAdminRole(groupId as string));

  const [focusTrap, setFocusTrap] = useState(true);

  const content = useRichContent(item?.entity?.body?.content);

  const form = useFormik<IFeedItemForm>({
    onSubmit: handleSubmit,
    validate: handleValidate,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      feedItemId,
      content: draft || content,
      groupId: groupId as string,
      title: item?.entity?.title || '',
      topics: item?.entity.topics || (forTopic ? [forTopic] : []),
    },
  });

  const isSubmitting = isCreating || isUpdating || form.isSubmitting;
  const isSubmitDisabled = isSubmitting || !form.isValid;

  useEffect(() => {
    if (!props.isOpen) {
      form.resetForm();
    }
  }, [props.isOpen]);

  useEffect(() => {
    const isCreated = isNewPost && didCreate;
    const isUpdated = !isNewPost && didUpdate;

    if (isCreated || isUpdated) {
      props.onClose();
    }
  }, [isNewPost, didUpdate, didCreate]);

  useEffect(() => {
    if (form.errors.topics) {
      bi.report(
        groupsNotificationShownSrc111Evid510({
          type: 'error',
          name: 'select_topic',
          page_name: 'create_post_livesite',
        }),
      );
    }
  }, [form.errors.topics]);

  useImperativeHandle(editorRef, () => $ricos.current as RicosEditorType);

  return (
    <InputDialog
      disableEscapeKeyDown
      onClose={handleClose}
      focusTrap={!isMobile && focusTrap}
      hideCloseButton={isMobile}
      resize="native"
      scalable={false}
      data-hook="feed-item-editor-dialog"
      paperProps={{ className: classes.paper }}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
      })}
      {...rest}
    >
      <Show if={isMobile}>
        <DialogMobileHeader>
          <TextButton
            onClick={handleCancel}
            data-hook="cancel"
            variant="secondary"
          >
            {cancelLabel || t('groups-web.discussion.new-post.mobile.back')}
          </TextButton>
          <TextButton
            loading={isSubmitting}
            onClick={form.submitForm}
            data-hook="submit"
            disabled={isSubmitDisabled}
          >
            {t('groups-web.discussion.new-post.mobile.post')}
          </TextButton>
        </DialogMobileHeader>
      </Show>

      <Hide if={isMobile}>
        <CardHeader
          verticalAlign="middle"
          avatar={
            <ProfileLink tabIndex={-1} profile={user}>
              <Avatar
                size="large"
                lettersLimit={2}
                name={user.name}
                src={user.imageUrl}
              />
            </ProfileLink>
          }
          title={<ProfileLink profile={user} />}
          titleProps={{ variant: 'p2-16' }}
          subtitle={
            !promoteGroup ? undefined : (
              <Trans
                values={{ groupName }}
                i18nKey="groups-web.discussion.new-post.create-post-in-group"
                components={[
                  <span key={0} />,
                  <Link key={1} state="group" params={{ slug }} />,
                ]}
              />
            )
          }
        />
      </Hide>

      <DialogContent
        scrollable={false}
        disableSideGutters
        className={classes.content}
      >
        <Box padding="0 SP5" marginBottom="SP5">
          <EditableText
            value={form.values?.title || ''}
            onChange={handleTitleChange}
            placeholder={t(
              'groups-web.discussion.new-post.post-title.placeholder',
            )}
            maxLength={120}
            autofocus
            className={classes.title}
          />
        </Box>
        <RicosEditor
          ref={$ricos}
          intent={intent}
          postId={form?.values?.feedItemId || ''}
          containerId={GROUPS_APP_DEFINITION_ID}
          data-hook="rich-content-editor"
          groupId={groupId}
          usage="NewPostModal"
          onChange={handleContentChange}
          onBusyChange={form.setSubmitting}
          content={form.values.content}
          placeholder={t('groups-web.discussion.new-post.placeholder')}
          fallback={<RceEditorFallback bw />}
          loadingPlaceholder={<RceEditorFallback bw />}
          modalSettings={{
            onModalOpen: handleRicosModalOpen,
            onModalClose: handleRicosModalClose,
          }}
        />
      </DialogContent>

      <Show if={isMobile}>
        <Box padding="SP0 SP5">
          <TopicsSelect
            isNewPost={isNewPost}
            feedSettings={feedSettings}
            error={form.errors.topics as string}
            value={form.values.topics}
            groupId={form.values.groupId}
            onChange={(topics) => {
              form.setFieldValue('topics', topics, true);
            }}
          />
        </Box>
      </Show>

      <Hide if={isMobile}>
        <DialogFooter align="space-between" verticalAlign="middle">
          <TopicsSelect
            isNewPost={isNewPost}
            value={form.values.topics}
            groupId={form.values.groupId}
            error={form.errors.topics as string}
            feedSettings={feedSettings}
            onChange={(topics) => {
              form.setFieldValue('topics', topics, true);
            }}
          />
          <ButtonGroup>
            <Button
              outlined
              variant="basic"
              onClick={handleCancel}
              data-hook="cancel"
            >
              {cancelLabel || t('groups-web.discussion.new-post.cancel')}
            </Button>
            <Button
              variant="basic"
              data-hook="submit"
              loading={isSubmitting}
              onClick={form.submitForm}
              disabled={isSubmitDisabled}
            >
              {t('groups-web.discussion.new-post.publish')}
            </Button>
          </ButtonGroup>
        </DialogFooter>
      </Hide>
    </InputDialog>
  );

  function handleTitleChange(value: string) {
    form.setFieldValue('title', value);
  }

  function handleContentChange(content: RichContent) {
    const helpers = $ricos.current?.getContentTraits();
    form.setFieldTouched('content', true);
    form.setFieldError('content', undefined);

    if (!helpers) {
      return;
    }

    if (helpers.isEmpty) {
      form.setFieldValue('content', undefined);
      form.setFieldError('content', 'true');
    }

    if (isNewPost || helpers.isContentChanged) {
      form.setFieldValue('content', content);
    } else {
      form.setFieldValue('content', form.initialValues.content);
    }
  }

  function handleValidate(values: IFeedItemForm) {
    const helpers = $ricos.current?.getContentTraits();
    const isEmpty = helpers ? helpers.isEmpty : true;
    const errors: FormikErrors<IFeedItemForm> = {};

    if (isEmpty) {
      errors.content = 'true';
    }

    if (!values.groupId) {
      errors.groupId = 'true';
    }

    if (feedSettings?.topicRequired && values.topics?.length === 0) {
      errors.topics = isAdmin
        ? t('groups-web.discussion.topics.modal.error.admin')
        : t('groups-web.discussion.topics.modal.error.member');
    }

    return errors;
  }

  function handleRicosModalOpen() {
    setFocusTrap(false);
  }

  function handleRicosModalClose() {
    setFocusTrap(true);
  }

  function handleSubmit(values: IFeedItemForm) {
    form.setSubmitting(true);

    const { feedItemId, groupId, topics, title } = values;
    const topicIds = topics.map((topic) => topic.id as string);

    editor
      .publish()
      .then(() =>
        $ricos.current?.getContentPromise({
          flush: true,
          publishId: feedItemId,
        }),
      )
      .then((ricosContent) => {
        const contentType =
          experiments.get(RichContentMigration[origin!]) || 'DRAFTJS';
        const content = serialize(ricosContent, contentType);

        if (feedItemId) {
          feed$.update({
            groupId,
            feedItemId,
            content,
            title,
            topics: topicIds,
            contentType,
          });
        } else {
          feed$.create({
            groupId,
            content,
            title,
            topics: topicIds,
            withComments: true,
            contentType,
          });
        }
      });

    bi.report(
      groupsModalButtonClick({
        button_name: 'publish',
        modal_name: 'create_post',
        group_id: groupId,
        user_role: user.role,
        ...(omit(biExtra, ['groupId']) || {}),
      }),
    );
  }

  function handleCancel() {
    props.onCancel ? props.onCancel() : props.onClose();
  }

  async function handleClose(event: React.SyntheticEvent) {
    const content = await $ricos.current?.getContent();

    props.onClose(event, content);
  }
}

FeedItemEditorDialog.displayName = 'FeedItemEditorDialog';
