import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { AllowPolicy } from '@wix/ambassador-social-groups-v2-group/types';

import {
  RadioButtonGroup,
  type RadioButtonGroupProps,
} from 'wui/RadioButtonGroup';
import { RadioButton } from 'wui/RadioButton';

import { selectIsSiteAdmin } from 'store/selectors';

export function AllowPolicyField(props: RadioButtonGroupProps) {
  const { value, ...rest } = props;

  const { t } = useTranslation();
  const isSiteAdmin = useSelector(selectIsSiteAdmin);

  return (
    <RadioButtonGroup
      value={value}
      disabled={value === AllowPolicy.OWNER && !isSiteAdmin}
      {...rest}
    >
      <RadioButton
        value={AllowPolicy.ALL_MEMBERS}
        label={t(
          'groups-web.group.settings.permissions-form.policy.all-members',
        )}
      />

      <RadioButton
        value={AllowPolicy.OWNER_AND_ADMINS}
        label={t('groups-web.group.settings.permissions-form.policy.admins')}
      />

      {isSiteAdmin && (
        <RadioButton
          value={AllowPolicy.OWNER}
          label={t('groups-web.group.settings.permissions-form.policy.owner')}
        />
      )}
    </RadioButtonGroup>
  );
}

AllowPolicyField.displayName = 'AllowPolicyField';
