import React from 'react';
import { type FormikProps } from 'formik';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';

import { AllowPolicy } from '@wix/ambassador-social-groups-v2-group/types';

import { Box, IBoxProps } from 'wui/Box';
import { RadioButton } from 'wui/RadioButton';
import { RadioButtonGroup } from 'wui/RadioButtonGroup';

import { type MemberPermissionsFormValues } from './types';
import { AllowPolicyField } from './AllowPolicyField';

interface IProps {
  form: FormikProps<MemberPermissionsFormValues>;
}

export function MemberPermissionsForm(props: IProps & IBoxProps) {
  const { form, ...rest } = props;

  const { t } = useTranslation();
  const { experiments } = useExperiments();

  if (!experiments.enabled('specs.groups.UpdatedMemberPermissions')) {
    return (
      <Box direction="vertical" {...rest}>
        <RadioButtonGroup
          {...form.getFieldProps('allowedToInviteMembers')}
          label={t('groups-web.group.settings.permissions.caption')}
          onChange={(value) =>
            form.setFieldValue('allowedToInviteMembers', value)
          }
        >
          <RadioButton
            value={AllowPolicy.ALL_MEMBERS}
            label={t('groups-web.group.settings.permissions.all-members')}
          />
          <RadioButton
            value={AllowPolicy.OWNER_AND_ADMINS}
            label={t(
              'groups-web.group.settings.permissions.admin-and-moderators',
            )}
          />
        </RadioButtonGroup>
      </Box>
    );
  }

  return (
    <Box gap="SP8" direction="vertical" {...rest}>
      <AllowPolicyField
        {...form.getFieldProps('allowedToCreatePosts')}
        onChange={(value) => form.setFieldValue('allowedToCreatePosts', value)}
        label={t(
          'groups-web.group.settings.permissions-form.create-posts.label',
        )}
      />

      <AllowPolicyField
        {...form.getFieldProps('allowedToInviteMembers')}
        onChange={(value) =>
          form.setFieldValue('allowedToInviteMembers', value)
        }
        label={t(
          'groups-web.group.settings.permissions-form.invite-members.label',
        )}
      />

      <AllowPolicyField
        {...form.getFieldProps('allowedToAddMembers')}
        onChange={(value) => form.setFieldValue('allowedToAddMembers', value)}
        label={t(
          'groups-web.group.settings.permissions-form.add-members.label',
        )}
      />
    </Box>
  );
}

MemberPermissionsForm.displayName = 'MemberPermissionsForm';
