import React from 'react';
import cls from 'classnames';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { DialogContent } from 'wui/DialogContent';
import { DialogTitle } from 'wui/DialogTitle';
import { TextArea } from 'wui/TextArea';
import { Divider } from 'wui/Divider';
import { Box } from 'wui/Box';
import { Media } from 'wui/Media';
import { InputDialog, IInputDialogProps } from 'wui/InputDialog';

import classes from './ImageSettingsDialog.scss';
import { FormField } from 'wui/FormField';

interface ImageSettingsDialogProps extends IInputDialogProps {
  altText: string;
  onAltTextChange(value: string): void;
  src: string;
}

export function ImageSettingsDialog(props: ImageSettingsDialogProps) {
  const { altText, onAltTextChange, src, ...dialogProps } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <InputDialog
      backdrop={false}
      size="auto"
      className={cls(classes.root, {
        [classes.mobile]: isMobile,
      })}
      {...dialogProps}
    >
      <DialogTitle title={t('group-web.image-settings-modal.title')} />
      <Divider />
      <DialogContent>
        <Box direction="vertical" gap="SP4" paddingBottom="SP4">
          <Box className={classes.mediaWrapper}>
            <Media
              fluid
              src={src}
              resize="cover"
              wired
              className={classes.media}
              alt={altText}
            />
          </Box>
          <FormField
            id="image-settings-alt-text"
            label={t('group-web.image-settings-modal.alt-text-input.label')}
            infoContent={t(
              'group-web.image-settings-modal.alt-text-input.info-tooltip',
            )}
          >
            <TextArea
              value={altText}
              onChange={(e) => onAltTextChange(e.target.value)}
              placeholder={t(
                'group-web.image-settings-modal.alt-text-input.placeholder',
              )}
              ariaLabel={t(
                'group-web.image-settings-modal.alt-text-input.label',
              )}
            />
          </FormField>
        </Box>
      </DialogContent>
    </InputDialog>
  );
}

ImageSettingsDialog.displayName = 'wui/ImageViewer/ImageSettingsDialog';
