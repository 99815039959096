import { useSelector } from 'react-redux';

import { selectIsJoinedGroupMember } from 'store/selectors';

import { useController } from 'common/context/controller';

export function usePolls(groupId?: string) {
  const { application$ } = useController();
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId!));

  return {
    onBeforeVote() {
      if (isJoined) {
        return Promise.resolve();
      }

      application$.showDialog({
        dialog: 'joinGroup',
        params: { groupId },
      });

      return Promise.reject();
    },
  };
}
