import React from 'react';
import cls from 'classnames';

import {
  Checkbox as TPACheckbox,
  CheckboxProps as TPACheckboxProps,
} from 'wix-ui-tpa/cssVars';

import classes from './Checkbox.scss';

export interface CheckboxProps
  extends Omit<TPACheckboxProps, 'onChange' | 'label' | 'error'> {
  onChange?: TPACheckboxProps['onChange'];
  label?: TPACheckboxProps['label'];
  error?: boolean | string;
}

export const Checkbox = React.forwardRef<TPACheckbox, CheckboxProps>(
  ({ label, className, error, errorMessage, onChange, ...rest }, ref) => {
    return (
      <div tabIndex={-1} onClick={handleClick}>
        <TPACheckbox
          ref={ref}
          error={!!error}
          label={label || ''}
          onChange={onChange || noop}
          className={cls(classes.root, className)}
          errorMessage={typeof error === 'string' ? error : errorMessage}
          {...rest}
        />
      </div>
    );

    function handleClick(event: React.MouseEvent<HTMLDivElement>) {
      event.stopPropagation();
    }
  },
);

function noop() {}

Checkbox.displayName = 'wui/Checkbox';
