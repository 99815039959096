import React from 'react';
import { render } from 'react-dom';

import { Groups as GroupsIcon } from '@wix/wix-ui-icons-common';

export async function getGroupsLogo(color: string) {
  const container = document.createElement('div');

  return new Promise<Blob>((resolve, reject) => {
    render(<GroupsIcon fill={color} />, container, () => {
      const svg = container.querySelector('svg');

      if (!svg) {
        return reject();
      }

      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

      resolve(new Blob([svg.outerHTML], { type: 'image/svg+xml' }));
    });
  });
}
