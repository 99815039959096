import {
  NotificationChannel,
  NotificationSettings,
  NotificationType,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';
import type { INotificationSettings } from 'store/groups/types';

const notificationTypes = {
  [NotificationType.POST]: 'posts',
  [NotificationType.COMMENT]: 'comments',
  [NotificationType.REACTION]: 'reactions',
  [NotificationType.MEMBERSHIP]: 'newMembers',
  [NotificationType.GROUP_UPDATES]: 'groupUpdates',
  [NotificationType.MEMBERS_JOIN]: 'membersJoined',
};

export function bootstrapBIChanges(
  prev: INotificationSettings,
  next: INotificationSettings,
) {
  try {
    return [
      {
        channel: NotificationChannel.WEB,
        tabName: 'website',
        all: 'websiteToggle',
      },
      {
        channel: NotificationChannel.EMAIL,
        tabName: 'email',
        all: 'emailToggle',
      },
      {
        channel: NotificationChannel.MOBILE,
        tabName: 'mobile_app',
        all: 'mobileAppToggle',
      },
    ]
      .map(({ channel, tabName, all }) => {
        const prevSettings = settingsToMap(prev[channel] || []);
        const nextSettings = settingsToMap(next[channel] || []);

        const didChange = Object.values(NotificationType).some(
          (key) => prevSettings[key] !== nextSettings[key],
        );

        if (!didChange) {
          return {};
        }

        return Object.values(NotificationType).reduce((acc, type) => {
          if (type === NotificationType.ALL) {
            return {
              ...acc,
              [all]: !nextSettings[type],
              tabName,
            };
          }

          return {
            ...acc,
            // @ts-expect-error
            [notificationTypes[type]]: !nextSettings[type],
          };
        }, {});
      })
      .filter((result) => Object.keys(result).length);
  } catch (e) {
    console.error(e);
    return [];
  }
}

function settingsToMap(
  settings: NotificationSettings[],
): NotificationSettingsMap {
  return settings.reduce((res, option) => {
    if (option.notificationType) {
      res[option.notificationType] = !!option.mute;
    }
    return res;
  }, {} as NotificationSettingsMap);
}

type NotificationSettingsMap = {
  [id in NotificationType]: boolean;
};
