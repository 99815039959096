import React, { useCallback } from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { pcrClickSrc111Evid220 } from '@wix/bi-logger-groups-data/v2';

import type { IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';
import { useController } from 'common/context/controller';
import { selectCurrentUser, selectIsSiteAdmin } from 'store/selectors';

import { Fade } from 'wui/Fade';
import { Stack, type IStackProps } from 'wui/Stack';

import { Reaction } from './Reaction';
import { PrimaryReaction } from './PrimaryReaction';
import { ReactionsSelect } from './ReactionsSelect';

import { selectReactionCodes, selectReactionsMap } from './selectors';

import classes from './styles.scss';

interface IProps extends Partial<IStackProps> {
  item: IFeedItem;

  onReact?(code?: string): void;
}

export function Reactions(props: IProps) {
  const { item, onReact, className, ...rest } = props;

  const { t } = useTranslation();
  const bi = useBi();

  const { isMobile } = useEnvironment();
  const { application$, feed$ } = useController();

  const feedItemId = item.feedItemId as string;
  const canReact = !!item.permissions?.canReact;
  const groupId = item.applicationContext?.contextId as string;

  const currentUser = useSelector(selectCurrentUser);
  const reactions = useSelector(selectReactionsMap(feedItemId));
  const isSiteAdmin = useSelector(selectIsSiteAdmin);

  const [primary, secondary] = useSelector(
    selectReactionCodes(feedItemId, ['👍']),
  );

  const didReact = useCallback(
    (code: string) => {
      return reactions[code]?.some(
        (reaction) => currentUser.siteMemberId === reaction.user.siteMemberId,
      );
    },
    [reactions],
  );

  if (!item.permissions?.canViewFullPost) {
    return <div />;
  }

  return (
    <Stack
      wrap
      as={TransitionGroup}
      className={cls(className, classes.root)}
      gap={isMobile ? 'SP2' : 'SP3'}
      {...rest}
    >
      {primary.map((code) => (
        <PrimaryReaction
          key={code}
          code={code}
          feedItemId={feedItemId}
          reacted={didReact(code)}
          data-hook="feed-item-reaction-primary"
          onClick={createClickHandler(code)}
        />
      ))}

      {secondary.map((code) => (
        <Fade key={code}>
          <Reaction
            code={code}
            feedItemId={feedItemId}
            reacted={didReact(code)}
            data-hook="feed-item-reaction-secondary"
            onClick={createClickHandler(code)}
          />
        </Fade>
      ))}

      <ReactionsSelect
        data-hook="reactions-select"
        onSelect={handleReactionToggle}
      />
    </Stack>
  );

  function createClickHandler(code: string) {
    return function () {
      handleReactionToggle(code);
    };
  }

  function handleReactionToggle(code: string) {
    if (didReact(code)) {
      return feed$.unreact(groupId, feedItemId, code);
    }

    bi.report(
      pcrClickSrc111Evid220({
        created_by: isSiteAdmin ? 'owner' : 'member',
        created_from: 'livesite',
        permission: canReact,
        react_to: 'post',
        reaction_type: code,
        type: 'reaction',
      } as any),
    );

    if (canReact) {
      feed$.react(groupId, feedItemId, code);
      onReact?.(code);
    } else {
      application$.showDialog({ dialog: 'joinGroup', params: { groupId } });
    }
  }
}

Reactions.displayName = 'LegacyReactions';
