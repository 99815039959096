import React from 'react';
import { useSelector } from 'react-redux';

import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { paidPostSubscribeClick } from '@wix/bi-logger-groups/v2';

import type { IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';

import { COMPONENT_ID } from 'common/utils';
import { useController } from 'common/context/controller';
import { selectGroupSlugById } from 'store/selectors';

import { EmptyState } from 'wui/EmptyState';
import { Button } from 'wui/Button';
import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';
import { IconButton } from 'wui/IconButton';
import { Lock as LockIcon } from '@wix/wix-ui-icons-common/on-stage';

import { GROUPS_APP_DEFINITION_ID } from '../../../../constants';

import { PricingPlanDetails } from './PricingPlanDetails';

import { getAriaId } from '../a11y';

import classes from '../FeedItem.scss';

interface IProps {
  item: IFeedItem;
  isSuggested?: boolean;
}

export function FeedItemPreview({ item, isSuggested }: IProps) {
  const { t } = useTranslation();
  const bi = useBi();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const plans = item.accessRestriction?.paidPlansOptions?.plans || [];

  const slug = useSelector(selectGroupSlugById(groupId));

  const { pricingPlans$ } = useController();

  return (
    <Box verticalAlign="space-between" align="center" direction="vertical">
      <Show if={!!item.preview?.title}>
        <Typography as="h3" variant="h2-20" className={classes.title}>
          {item.preview?.title}
        </Typography>
      </Show>
      <EmptyState
        variant="section"
        id={getAriaId(feedItemId, 'preview')}
        title={
          <Box direction="vertical" align="center">
            <Box
              style={{
                padding: 10,
                border: '1px solid ',
                borderRadius: '50%',
              }}
            >
              <IconButton
                icon={<LockIcon width={24} height={24} />}
                onClick={navigateToPackagePicker}
              />
            </Box>
            <Typography variant="h2-20">
              {t('groups-web.discussion.feed.paid-post.preview.title')}
            </Typography>
          </Box>
        }
        subtitle={<PricingPlanDetails plan={plans[0]} />}
        action={
          plans.length ? (
            <Button onClick={navigateToPackagePicker}>
              {t('groups-web.discussion.feed.paid-post.preview.cta')}
            </Button>
          ) : undefined
        }
      />
    </Box>
  );

  function navigateToPackagePicker() {
    bi.report(
      paidPostSubscribeClick({
        group_id: groupId,
        post_id: feedItemId,
        post_details: JSON.stringify({
          type: isSuggested ? 'suggested' : 'default',
          photo: item.mediaData?.imageCount,
          video: item.mediaData?.videoCount,
          document: item.mediaData?.fileCount,
        }),
      }),
    );

    pricingPlans$.navigateToPackagePicker({
      planIds: item.accessRestriction?.paidPlansOptions?.planIds,
      biOptions: {
        referralInfo: 'groups_paid_post',
        referralId: feedItemId,
      },
      checkout: {
        successStatus: {
          content: {
            title: t('groups-web.restriction.paid-post.success.title'),
            message: t('groups-web.restriction.paid-post.success.body'),
            cta: t('groups-web.restriction.paid-post.success.cta'),
          },
          navigation: {
            type: 'section',
            options: {
              appDefinitionId: GROUPS_APP_DEFINITION_ID,
              sectionId: COMPONENT_ID.GROUP,
              state: `${slug}/discussion/${feedItemId}`,
            },
          },
        },
      },
    });
  }
}

FeedItemPreview.displayName = 'FeedItemPreview';
