import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  NotificationChannel,
  NotificationSettings,
  NotificationType,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';

import { Box } from 'wui/Box';
import { Divider } from 'wui/Divider';
import { Checkbox } from 'wui/Checkbox';
import { Typography } from 'wui/Typography';
import { ToggleSwitch } from 'wui/ToggleSwitch';
import { Stack } from 'wui/Stack';

export const NotificationCaptions = {
  [NotificationChannel.WEB]: 'groups-web.notifications.website.caption',
  [NotificationChannel.MOBILE]: 'groups-web.notifications.mobile.caption',
  [NotificationChannel.EMAIL]: 'groups-web.notifications.email.caption',
};

export const NotificationTitleChannels = {
  [NotificationChannel.EMAIL]: 'groups-web.notifications.email',
  [NotificationChannel.MOBILE]: 'groups-web.notifications.mobile',
  [NotificationChannel.WEB]: 'groups-web.notifications.website',
};

export const NotificationTitles = {
  [NotificationChannel.EMAIL]: 'groups-web.notifications.email.title',
  [NotificationChannel.MOBILE]: 'groups-web.notifications.mobile.title',
  [NotificationChannel.WEB]: 'groups-web.notifications.website.title',
};

type HandledNotificationType = Exclude<
  NotificationType,
  NotificationType.ALL | NotificationType.WEEKLY_DIGEST
>;

const NotificationGroup = {
  [NotificationType.POST]: {
    name: 'groups-web.notifications.posts',
    label: 'groups-web.notifications.posts',
    caption: 'groups-web.notifications.posts.caption',
  },
  [NotificationType.COMMENT]: {
    name: 'groups-web.notifications.comments',
    label: 'groups-web.notifications.comments',
    caption: 'groups-web.notifications.comments.caption',
  },
  [NotificationType.REACTION]: {
    name: 'groups-web.notifications.reactions',
    label: 'groups-web.notifications.reactions',
    caption: 'groups-web.notifications.reactions.caption',
  },
  [NotificationType.GROUP_UPDATES]: {
    name: 'groups-web.notifications.group_updates',
    label: 'groups-web.notifications.group_updates',
    caption: 'groups-web.notifications.group_updates.caption',
  },
  [NotificationType.MEMBERSHIP]: {
    name: 'groups-web.notifications.members',
    label: 'groups-web.notifications.members',
    caption: 'groups-web.notifications.members.caption',
  },
  [NotificationType.MEMBERS_JOIN]: {
    name: 'groups-web.notifications.members_join',
    label: 'groups-web.notifications.members_join',
    caption: 'groups-web.notifications.members_join.caption',
  },
} as const;

interface ChannelSettingsProps {
  settings: NotificationSettings[];
  channel: NotificationChannel;
  onChange(updatedSettings: NotificationSettings[]): void;
}

export const ChannelSettings: React.FC<ChannelSettingsProps> = ({
  settings,
  channel,
  onChange,
}) => {
  const { t } = useTranslation();
  const title = t(NotificationTitles[channel], {
    channel: t(NotificationTitleChannels[channel]),
  });
  const caption = t(NotificationCaptions[channel]);

  const allOption = getOptionByType(NotificationType.ALL);

  if (!allOption) {
    return null;
  }

  return (
    <Box direction="vertical" gap="SP4">
      <Box gap="SP4" direction="horizontal" verticalAlign="top">
        <Box gap="SP1" width="100%" direction="vertical">
          <Typography as="h3" variant="h2-20">
            {title}
          </Typography>

          <Typography secondary variant="p2-14">
            {caption}
          </Typography>
        </Box>

        <ToggleSwitch
          checked={!allOption.mute}
          onChange={() => mute(NotificationType.ALL)}
          aria-label={title}
        />
      </Box>

      <Divider bw />

      <Stack direction="vertical" gap="SP4">
        {renderCheckbox(NotificationType.POST)}
        {renderCheckbox(NotificationType.COMMENT)}
        {channel !== NotificationChannel.EMAIL &&
          renderCheckbox(NotificationType.REACTION)}
        {renderCheckbox(NotificationType.MEMBERSHIP)}
        {renderCheckbox(NotificationType.GROUP_UPDATES)}
        {renderCheckbox(NotificationType.MEMBERS_JOIN)}
      </Stack>
    </Box>
  );

  function renderCheckbox(key: HandledNotificationType) {
    const keys = NotificationGroup[key];
    const option = getOptionByType(key);
    if (!option) {
      return null;
    }

    return (
      <Box direction="vertical" gap="SP0">
        <Checkbox
          disabled={!!allOption?.mute}
          name={t(keys.label)}
          checked={!option.mute}
          onChange={() => mute(key)}
          label={t(keys.name)}
        />
        <Typography secondary variant="p2-14">
          {t(keys.caption)}
        </Typography>
      </Box>
    );
  }

  function mute(type: NotificationType) {
    if (type === NotificationType.ALL) {
      muteAll();
    } else {
      muteType(type);
    }
  }

  function muteAll() {
    const allTypeIndex = settings.findIndex(
      (s) => s.notificationType === NotificationType.ALL,
    );
    if (allTypeIndex === -1) {
      return;
    }

    const updatedSettings = settings.map((s) => {
      return {
        ...s,
        mute: !settings[allTypeIndex].mute,
      };
    });
    onChange(updatedSettings);
  }

  function muteType(type: NotificationType) {
    const updatedSettings = [...settings];
    const indexToUpdate = updatedSettings.findIndex(
      (s) => s.notificationType === type,
    );
    if (indexToUpdate === -1) {
      return;
    }

    updatedSettings[indexToUpdate] = {
      ...updatedSettings[indexToUpdate],
      mute: !updatedSettings[indexToUpdate].mute,
    };
    onChange(updatedSettings);
  }

  function getOptionByType(type: NotificationType) {
    return settings.find((s) => s.notificationType === type);
  }
};

ChannelSettings.displayName = 'ChannelSettings';
