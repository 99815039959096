import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { type IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';
import { selectReactedUsers } from 'store/selectors';

import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Tooltip } from 'wui/Tooltip';
import { Typography } from 'wui/Typography';
import { Like as LikeIcon } from '@wix/wix-ui-icons-common/on-stage';

import { FeedItemStat, type IFeedItemStatProps } from '../FeedItemStat';

export interface IReactionsStatProps extends Partial<IFeedItemStatProps> {
  item: IFeedItem;
}

export function ReactionsStat(props: IReactionsStatProps) {
  const { item, ...rest } = props;

  const { t } = useTranslation();

  const users = useSelector(selectReactedUsers(item.feedItemId as string));

  const previewProfiles = _.take(users, 20).map((user) => ({
    name: user.name,
    src: user.imageUrl,
  }));

  return (
    <Tooltip
      placement="top"
      content={
        <Stack direction="vertical">
          {previewProfiles.map((profile, index) => (
            <Typography noWrap variant="p2-12" key={index}>
              {profile.name}
            </Typography>
          ))}
          <Show if={previewProfiles.length < users.length}>
            <span>...</span>
          </Show>
        </Stack>
      }
    >
      <FeedItemStat
        count={item.reactions.total}
        icon={<LikeIcon />}
        label={t('groups-web.discussion.feed.reactions.total_icu', {
          count: item.reactions.total,
        })}
        {...rest}
      />
    </Tooltip>
  );
}

ReactionsStat.displayName = 'ReactionsStat';
