import { curryRight, without } from 'lodash';
import { createSelector } from 'reselect';

import { selectReactions } from 'store/selectors';
import { type IUserReaction } from '@wix/social-groups-serverless/dist/feed/types';

export const selectReactionCodes = curryRight(
  createSelector(
    [selectReactions, (_, __, primary: string[]) => primary],
    (summary, primary) => {
      const reactions = summary?.reactions.map((reaction) => reaction.code);

      return [primary, without(reactions, ...primary)];
    },
  ),
  3,
);

export const selectReactionsMap = curryRight(
  createSelector(
    selectReactions,
    (reactions) =>
      reactions?.reactions.reduce((acc, reaction) => {
        return {
          ...acc,
          [reaction.code]: acc[reaction.code]?.concat([reaction]) ?? [reaction],
        };
      }, {} as Record<string, IUserReaction[]>) ?? {},
  ),
  2,
);
