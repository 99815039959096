import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useController } from 'common/context/controller';

import {
  selectCanAddMembers,
  selectCanInviteMembers,
  selectIsGroupPending,
  selectDialogs,
} from 'store/selectors';
import { IApplicationState } from 'store/application';

import {
  AddUser as AddUserIcon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Email as EmailIcon,
  Plus as PlusIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { Button } from 'wui/Button';
import { Menu, MenuItem } from 'wui/Menu';

import { AddMembersDialog } from './AddMembersDialog';
import { InviteMembersDialog } from './InviteMembersDialog';
import { PendingApprovalDialog } from './PendingApprovalDialog';

type IDialogs = Pick<
  IApplicationState['dialogs'],
  'addMembers' | 'inviteMembers'
>;

interface IProps {
  groupId: string;
}

export function AddInviteMembers(props: IProps) {
  const { groupId } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { application$ } = useController();
  const { addMembers: addMembersDialog, inviteMembers: inviteMembersDialog } =
    useSelector(selectDialogs);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isPendingApprovalOpen, setIsPendingApprovalOpen] = useState(false);

  const canAdd = useSelector(selectCanAddMembers(groupId));
  const canInvite = useSelector(selectCanInviteMembers(groupId));
  const isPending = useSelector(selectIsGroupPending(groupId));

  if (!canAdd && !canInvite) {
    return null;
  }

  if (isPending) {
    return (
      <>
        <Button
          outlined
          fullWidth={isMobile}
          onClick={handlePendingModalOpen}
          data-hook="add-invite-members-button"
          area-label={t('groups-web.a11y.invite-members')}
          prefixIcon={<PlusIcon />}
          suffixIcon={
            isPendingApprovalOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
          }
        >
          {t('groups-web.add-invite.button.label')}
        </Button>
        <PendingApprovalDialog
          isOpen={isPendingApprovalOpen}
          onClose={() => setIsPendingApprovalOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <Menu
        onClose={handleMenuClose}
        popoverProps={{ showArrow: false }}
        element={
          <Button
            outlined
            fullWidth={isMobile}
            onClick={handleMenuOpen}
            data-hook="add-invite-members-button"
            area-label={t('groups-web.a11y.invite-members')}
            prefixIcon={<PlusIcon />}
            suffixIcon={isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          >
            {t('groups-web.add-invite.button.label')}
          </Button>
        }
      >
        {canInvite && (
          <MenuItem
            prefixIcon={<EmailIcon />}
            content={t('groups-web.add-invite.menu.invite.title')}
            subtitle={t('groups-web.add-invite.menu.invite.subtitle')}
            onClick={handleDialogOpen('inviteMembers')}
          />
        )}
        {canAdd && (
          <MenuItem
            prefixIcon={<AddUserIcon />}
            content={t('groups-web.add-invite.menu.add.title')}
            subtitle={t('groups-web.add-invite.menu.add.subtitle')}
            onClick={handleDialogOpen('addMembers')}
          />
        )}
      </Menu>

      <AddMembersDialog
        groupId={props.groupId}
        data-hook="add-members-dialog"
        isOpen={addMembersDialog.isOpen}
        onClose={handleDialogClose('addMembers')}
      />

      <InviteMembersDialog
        groupId={props.groupId}
        data-hook="invite-members-dialog"
        isOpen={inviteMembersDialog.isOpen}
        biExtra={inviteMembersDialog.params?.biExtra}
        onClose={handleDialogClose('inviteMembers')}
      />
    </>
  );

  function handleDialogOpen(dialog: keyof IDialogs) {
    return function () {
      handleMenuClose();
      application$.showDialog({ dialog });
    };
  }

  function handleDialogClose(dialog: keyof IDialogs) {
    return function () {
      handleMenuClose();
      application$.closeDialog(dialog);
    };
  }

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handlePendingModalOpen() {
    setIsPendingApprovalOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }
}

AddInviteMembers.displayName = 'AddInviteMembers';
