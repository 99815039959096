import type { RicosTheme } from '@wix/ricos';
import classes from './ricos.scss';

export const viewerTheme: RicosTheme = {
  parentClass: classes.viewer,
  customStyles: {
    h1: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'calc(2 * var(--postsTextFont-size))',
    },
    h2: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'calc(1.5 * var(--postsTextFont-size))',
    },
    h3: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'calc(1.17 * var(--postsTextFont-size))',
    },
    h4: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'calc(1 * var(--postsTextFont-size))',
    },
    h5: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'calc(0.83 * var(--postsTextFont-size))',
    },
    h6: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'calc(0.67 * var(--postsTextFont-size))',
    },
    p: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'var(--postsTextFont-size)',
    },
    quote: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'var(--postsTextFont-size)',
    },
    link: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'var(--postsTextFont-size)',
    },
    hashtag: {
      fontStyle: 'var(--postsTextFont-style)',
      fontWeight: 'var(--postsTextFont-weight)',
      textDecoration: 'var(--postsTextFont-text-decoration)',
      fontSize: 'var(--postsTextFont-size)',
    },
    editor: {
      minHeight: '270px',
    },
  },
  typography: {
    fontFamily: 'var(--postsTextFont-family)',
  },
  settingsStyles: {
    modals: {},
  },
};

export const viewerWiredTheme: RicosTheme = {
  ...viewerTheme,
  palette: {
    // @ts-expect-error
    ...viewerTheme?.palette,
    type: 'rgb',
    textColor: 'var(--postsTextColor)',
    bgColor: 'var(--paperBackgroundColor)',
    actionColor: 'var(--wix-color-8)',
    disabledColor: 'var(--wix-color-3)',
  },
  settingsStyles: {
    ...viewerTheme?.settingsStyles,
    icons: {
      ...viewerTheme?.settingsStyles?.icons,
      color: 'var(--postsTextColor)',
    },
  },
  paletteConfig: {
    ...viewerTheme?.paletteConfig,
    settingsActionColor: 'var(--wix-color-8)',
  },
  // text-decoration-color: rgb(var(--postsTextColor));
};
