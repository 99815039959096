import React from 'react';

import {
  Like as LikeIcon,
  LikeHeart as LikeHeartIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Reaction, type IReactionProps } from '../Reaction';

export function PrimaryReaction(props: IReactionProps) {
  if (props.reacted) {
    return <Reaction {...props} />;
  }

  switch (props.code) {
    case '👍':
      return (
        <Reaction
          {...props}
          icon={<LikeIcon data-hook="unreacted-reaction-icon" size={16} />}
        />
      );

    case '❤️':
      return (
        <Reaction
          {...props}
          icon={<LikeHeartIcon data-hook="unreacted-reaction-icon" size={16} />}
        />
      );

    default:
      return <Reaction {...props} />;
  }
}

PrimaryReaction.displayName = 'PrimaryReaction';
