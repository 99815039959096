import React from 'react';

import { Box } from '../Box';

interface IProps extends React.ComponentProps<typeof Box> {}

export function DialogFooter(props: IProps) {
  return <Box padding="SP0 SP6" {...props} />;
}

DialogFooter.displayName = 'wui/DialogFooter';
