import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { selectGroupName } from 'store/selectors';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { SearchDrawer } from 'common/components/SearchDrawer';
import { FeedSearchResults } from 'common/components/FeedSearchResults';

import { type IDrawerProps } from 'wui/Drawer';

interface IProps extends IDrawerProps {
  groupId: string;
}

export function GroupSearch({ groupId, isOpen, onClose, ...rest }: IProps) {
  const { params } = useCurrentStateAndParams();
  const { t } = useTranslation();
  const { search$ } = useController();

  const tabs = [
    { id: 'posts', title: t('groups-web.groups-search.tabs.posts') },
  ];

  const groupName = useSelector(selectGroupName(groupId as string));

  // autoclose on navigation
  useDidUpdate(() => {
    onClose();
  }, [params.feedItemId]);

  return (
    <SearchDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('groups-web.group-search.modal.title')}
      placeholder={t('groups-web.group-search.placeholder')}
      groupId={groupId}
      origin="group_page"
      tabs={tabs}
      {...rest}
    >
      {(query, containerRef, _, handleReset) => (
        <FeedSearchResults
          query={query}
          groupId={groupId}
          onReset={handleReset}
          container={containerRef.current}
          origin="group_page"
          searchConfig={{
            onSearch: (query: string) => search$.query({ query, groupId }),
            onSearchMore: (query: string, offset: number) =>
              search$.query({
                query,
                groupId,
                paging: {
                  offset,
                },
              }),
            emptyTitle: t('groups-web.group-search.empty-state.title'),
            emptySubtitle: t('groups-web.group-search.empty-state.subtitle', {
              groupName,
            }),
            errorTitle: t('groups-web.group-search.error-state.title'),
            errorSubtitle: t('groups-web.group-search.error-state.subtitle'),
            actionLabel: t('groups-web.group-search.error-state.retry'),
          }}
        />
      )}
    </SearchDrawer>
  );
}

GroupSearch.displayName = 'GroupSearch';
