import React from 'react';
import cls from 'classnames';
import {
  ToggleSwitch as TPAToggleSwitch,
  ToggleSwitchProps,
} from 'wix-ui-tpa/cssVars';

import classes from './ToggleSwitch.scss';

export interface IToggleSwitchProps
  extends Omit<ToggleSwitchProps, 'onChange'> {
  name?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export function ToggleSwitch(props: IToggleSwitchProps) {
  const { className, onChange, ...rest } = props;

  return (
    <TPAToggleSwitch
      id={props.name}
      onChange={onChange as any}
      className={cls(classes.root, props.className)}
      {...rest}
    />
  );
}

ToggleSwitch.displayName = 'wui/ToggleSwitch';

export type { ToggleSwitchProps };
