import { type GroupSettings } from '@wix/ambassador-social-groups-v2-group/types';

import { type MemberPermissionsFormValues } from './types';

export function selectMemberPermissionsFormValues(
  settings: GroupSettings,
): MemberPermissionsFormValues {
  return {
    allowedToAddMembers: settings.allowedToAddMembers,
    allowedToCreatePosts: settings.allowedToCreatePosts,
    allowedToInviteMembers: settings.allowedToInviteMembers,
  };
}
