import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import { Option, Select } from 'wui/Select';
import { Box } from 'wui/Box';
import { Fade } from 'wui/Fade';
import SecretIcon from 'assets/icons/Secret.svg';

import {
  Lock as LockIcon,
  Public as PublicIcon,
  Visible as VisibleIcon,
} from '@wix/wix-ui-icons-common/on-stage';

interface IGroupPrivacySelectProps {
  value: PrivacyStatus;
  'data-hook'?: string;
  onChange(privacy: PrivacyStatus): void;
}

export const GroupPrivacySelect: React.FC<IGroupPrivacySelectProps> = (
  props,
) => {
  const { onChange, value, 'data-hook': dataHook } = props;
  const { t } = useTranslation();

  return (
    <Box data-hook={dataHook} direction="vertical" gap="SP4">
      <Select
        onChange={handleChange}
        initialSelectedId={
          value === PrivacyStatus.SECRET ? PrivacyStatus.PRIVATE : value
        }
        label={t('groups-web.group.settings.type')}
        data-hook="privacy-select"
        options={[
          {
            isSelectable: true,
            id: PrivacyStatus.PUBLIC,
            value: t('groups-web.types.public.name'),
            subtitle: t('groups-web.types.public.description'),
            icon: (
              <PublicIcon
                data-hook={`group-privacy-option-${PrivacyStatus.PUBLIC}`}
              />
            ),
          },
          {
            isSelectable: true,
            id: PrivacyStatus.PRIVATE,
            value: t('groups-web.types.private.name'),
            subtitle: t('groups-web.types.private.description'),
            icon: (
              <LockIcon
                data-hook={`group-privacy-option-${PrivacyStatus.PRIVATE}`}
              />
            ),
          },
        ]}
      />

      <Fade
        mountOnEnter
        unmountOnExit
        in={[PrivacyStatus.PRIVATE, PrivacyStatus.SECRET].includes(value)}
      >
        <Select
          onChange={handleChange}
          initialSelectedId={value}
          data-hook="visibility-select"
          label={t('groups-web.group.settings.visibility')}
          options={[
            {
              isSelectable: true,
              id: PrivacyStatus.PRIVATE,
              value: t('groups-web.visibility.visible'),
              subtitle: t('groups-web.visibility.visible.label'),
              icon: (
                <VisibleIcon
                  data-hook={`group-visibility-option-${PrivacyStatus.PRIVATE}`}
                />
              ),
            },
            {
              isSelectable: true,
              id: PrivacyStatus.SECRET,
              value: t('groups-web.visibility.hidden'),
              subtitle: t('groups-web.visibility.hidden.label'),
              icon: (
                <SecretIcon
                  data-hook={`group-visibility-option-${PrivacyStatus.SECRET}`}
                />
              ),
            },
          ]}
        />
      </Fade>
    </Box>
  );

  function handleChange(option: Option) {
    onChange(option.id as PrivacyStatus);
  }
};

GroupPrivacySelect.displayName = 'GroupTypeSelect';
