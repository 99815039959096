import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import {
  groupFeedTopicsAddTopicClickIntent,
  groupFeedTopicsTopicActions,
} from '@wix/bi-logger-groups/v2';

import type { ITopic } from '@wix/social-groups-serverless/dist/topics/types';
import {
  filterTopics,
  selectAllTopics,
  selectFeedPermissionsByGroup,
  selectHasAdminRole,
  selectTopicsStatus,
} from 'store/selectors';

import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { TextField } from 'wui/TextField';
import { DialogContent } from 'wui/DialogContent';
import { Stack } from 'wui/Stack';
import { TextButton } from 'wui/TextButton';
import { Plus as PlusIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Spinner } from 'wui/Spinner';

import { TopicsEmptyState } from './EmptyState';
import { CreateTopic } from './CreateTopic';

import { Topic } from '../../Topic';

interface IProps extends Omit<React.ComponentProps<typeof Box>, 'onChange'> {
  isNewPost?: boolean;
  groupId: string;
  value: ITopic[];

  onChange(topics: ITopic[]): void;
}

export function TopicsForm(props: IProps) {
  const { groupId, value, onChange, isNewPost, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { topics$ } = useController();

  const [query, setQuery] = React.useState('');
  const [isCreateTopicOpen, setIsCreateTopicOpen] = React.useState(false);

  const topics = useSelector(selectAllTopics);
  const filteredTopics = useSelector(filterTopics(query));
  const statuses = useSelector(selectTopicsStatus);
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));
  const isAdmin = useSelector(selectHasAdminRole(groupId));

  if (isCreateTopicOpen) {
    return (
      <CreateTopic
        onCreate={handleCreateTopic}
        onClose={() => setIsCreateTopicOpen(false)}
      />
    );
  }

  if (statuses.fetch.pending) {
    return (
      <Box
        direction="vertical"
        verticalAlign="middle"
        align="center"
        height="100%"
        aria-busy
      >
        <Spinner />
      </Box>
    );
  }

  if (!topics.length) {
    return (
      <TopicsEmptyState
        onAdd={isAdmin ? () => setIsCreateTopicOpen(true) : undefined}
      />
    );
  }

  return (
    <>
      <TextField
        autoFocus
        value={query}
        withClearButton
        onChange={handleQueryChange}
        onClear={handleQueryClear}
        maxLength={40}
        placeholder={t('groups-web.discussion.topics.modal.search.placeholder')}
      />
      <DialogContent disableSideGutters visibleFocusRing>
        <Box
          direction="vertical"
          verticalAlign={filteredTopics.length ? 'space-between' : 'bottom'}
          height="100%"
        >
          <Show if={!!filteredTopics.length}>
            <Stack direction="vertical" gap="SP1">
              {filteredTopics.map((topic) => (
                <Topic
                  key={topic.id}
                  topic={topic}
                  data-hook="topic"
                  onClick={handleSelectTopic}
                  onRemove={handleRemoveTopic}
                  active={isSelected(topic.id)}
                  isRemovable={isSelected(topic.id)}
                />
              ))}
            </Stack>
          </Show>
          <Show if={permissions.canCreateTopic}>
            <TextButton
              prefixIcon={<PlusIcon />}
              disabled={statuses.create.pending}
              variant="secondary"
              onClick={() => setIsCreateTopicOpen(true)}
            >
              {t('groups-web.discussion.topics.modal.create-button')}
            </TextButton>
          </Show>
        </Box>
      </DialogContent>
    </>
  );

  function handleQueryClear() {
    setQuery('');
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function isSelected(id?: string) {
    return value.some((topic) => topic.id === id);
  }

  function handleRemoveTopic(topic: ITopic) {
    onChange(value.filter(({ id }) => id !== topic.id));

    bi.report(
      groupFeedTopicsTopicActions({
        groupId,
        action: 'delete',
        origin: !isNewPost ? 'post_creation' : 'post_to_the_topic',
        topicName: topic.displayName,
        topicId: topic.id,
      }),
    );
  }

  function handleSelectTopic(topic: ITopic) {
    onChange([...value, topic]);

    if (!isNewPost) {
      bi.report(
        groupFeedTopicsTopicActions({
          groupId,
          action: 'edit',
          origin: 'post_to_the_topic',
          topicName: topic.displayName,
          topicId: topic.id,
        }),
      );
    } else {
      bi.report(
        groupFeedTopicsAddTopicClickIntent({
          groupId,
          origin: 'topics_list_in_post',
        }),
      );
    }
  }

  function handleCreateTopic(name: string) {
    topics$.create(groupId, name);
    handleQueryClear();
    setIsCreateTopicOpen(false);
    bi.report(
      groupFeedTopicsAddTopicClickIntent({
        groupId,
        origin: 'topics_list_plus_btn_in_post',
      }),
    );
  }
}

TopicsForm.displayName = 'TopicsForm';
