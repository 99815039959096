import React, { useMemo, useRef } from 'react';
import * as yup from 'yup';
import { Formik, FieldArray, FormikHelpers } from 'formik';
import { useSelector } from 'react-redux';
import { useBi, useErrorMonitor, useTranslation } from '@wix/yoshi-flow-editor';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';
import Sortable, { SortableItem, SortableKnob } from 'react-easy-sort';

import {
  selectAllApplications,
  selectAreApplicationsUpdating,
} from 'store/selectors';
import { type GroupApp, GroupAppKey } from 'store/groups/types';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { ToggleSwitch } from 'wui/ToggleSwitch';
import { DialogContent } from 'wui/DialogContent';
import { DialogActions } from 'wui/DialogActions';
import { TextField } from 'wui/TextField';
import { Stack } from 'wui/Stack';
import { IconButton } from 'wui/IconButton';
import { usePortalContainer } from 'wui/Portal';
import { Rearrangeable as RearrangeableIcon } from '@wix/wix-ui-icons-common/on-stage';

import styles from './ApplicationsTab.scss';

const SortableList = Sortable as React.ComponentType<
  Omit<React.ComponentProps<typeof Sortable>, 'as'>
>;

interface GroupApplicationsForm {
  applications: GroupApp[];
}

interface IProps {
  groupId: string;

  onClose(): void;
}
export function ApplicationsTab(props: IProps) {
  const bi = useBi();
  const { t } = useTranslation();
  const { group$ } = useController();
  const container = usePortalContainer();
  const errorMonitor = useErrorMonitor();

  const $container = useRef(container);
  const isUpdating = useSelector(selectAreApplicationsUpdating(props.groupId));
  const applications = useSelector(selectAllApplications(props.groupId));

  const schema = useMemo(
    () =>
      yup.object().shape({
        applications: yup.array().of(
          yup.object().shape({
            customName: yup
              .string()
              .trim()
              .required(
                t('groups-web.group.settings.tabs.name.required.error'),
              ),
          }),
        ),
      }),
    [],
  );

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialValues={{
        applications: applications.map<GroupApp>((application) => ({
          ...application,
          customName: getTabName(application),
        })),
      }}
    >
      {(form) => (
        <>
          <DialogContent divider>
            <Box direction="vertical" gap="SP5">
              <Typography secondary>
                {t('groups-web.group.settings.tabs.caption')}
              </Typography>

              <FieldArray name="applications">
                {(fieldHelpers) => (
                  <Stack
                    gap="SP0"
                    lockAxis="y"
                    direction="vertical"
                    as={SortableList}
                    customHolderRef={$container}
                    onSortEnd={fieldHelpers.move}
                    draggedItemClassName={styles.dragged}
                  >
                    {form.values.applications.map((application, index) => (
                      <SortableItem key={application.key}>
                        <Box gap="SP4" verticalAlign="middle">
                          <SortableKnob>
                            <IconButton
                              type="button"
                              tabIndex={-1}
                              className={styles.knob}
                              icon={<RearrangeableIcon />}
                            />
                          </SortableKnob>

                          <Box width="100%" direction="vertical">
                            <TextField
                              showCharCount
                              maxLength={20}
                              theme="line"
                              disabled={
                                !form.values.applications[index].installed
                              }
                              {...form.getFieldProps(
                                `applications.${index}.customName`,
                              )}
                              {...form.getFieldMeta(
                                `applications.${index}.customName`,
                              )}
                            />
                          </Box>

                          <ToggleSwitch
                            {...form.getFieldProps({
                              type: 'checkbox',
                              name: `applications.${index}.installed`,
                            })}
                            disabled={[
                              GroupAppKey.FEED_APP,
                              GroupAppKey.ABOUT_APP,
                            ].includes(application.key as GroupAppKey)}
                          />
                        </Box>
                      </SortableItem>
                    ))}
                  </Stack>
                )}
              </FieldArray>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              outlined
              fullWidth
              type="button"
              variant="basic"
              onClick={props.onClose}
            >
              {t('groups-web.cancel')}
            </Button>
            <Button
              fullWidth
              type="submit"
              variant="basic"
              onClick={form.submitForm}
              loading={form.isSubmitting}
              disabled={form.isSubmitting || !form.isValid || !form.dirty}
            >
              {t('groups-web.save')}
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  );

  function handleSubmit(
    values: GroupApplicationsForm,
    helpers: FormikHelpers<GroupApplicationsForm>,
  ) {
    helpers.setSubmitting(true);

    const applications = values.applications.map((application, index) => ({
      ...application,
      tabOrderIndex: index,
    }));

    bi.report(formatBi(props.groupId, applications));
    group$.configureApps(props.groupId, applications);
  }

  function getTabName(groupApp: GroupApp): string {
    if (typeof groupApp.customName === 'undefined') {
      switch (groupApp.key) {
        case GroupAppKey.FEED_APP:
          return t('groups-web.group.settings.tabs.discussion');
        case GroupAppKey.GALLERY_APP:
          return t('groups-web.group.settings.tabs.media');
        case GroupAppKey.MEMBERS_APP:
          return t('groups-web.group.settings.tabs.members');
        case GroupAppKey.ABOUT_APP:
          return t('groups-web.group.settings.tabs.about');
        case GroupAppKey.EVENTS_APP:
          return t('groups-web.group.settings.tabs.events');
        case GroupAppKey.FILES_APP:
          return t('groups-web.group.settings.tabs.files');
        case GroupAppKey.CUSTOM_APP:
        case GroupAppKey.CUSTOM_APP_2:
        case GroupAppKey.CUSTOM_APP_3:
          return t('groups-web.group.settings.tabs.custom');
        default:
          return '';
      }
    }

    return groupApp.customName as string;
  }
}

ApplicationsTab.displayName = 'ApplicationsTab';

function formatBi(groupId: string, applications: GroupApp[]) {
  return groupSettingsSave({
    group_id: groupId,
    origin: 'save_button',
    tabName: 'tabs',
    show_media: getApplicationByKey(GroupAppKey.GALLERY_APP).installed!,
    show_members: getApplicationByKey(GroupAppKey.MEMBERS_APP).installed!,
    edit_tab: JSON.stringify({
      discussion: getApplicationByKey(GroupAppKey.FEED_APP).customName,
      media: getApplicationByKey(GroupAppKey.GALLERY_APP).customName,
      members: getApplicationByKey(GroupAppKey.MEMBERS_APP).customName,
      about: getApplicationByKey(GroupAppKey.ABOUT_APP).customName,
    }),
  });

  function getApplicationByKey(key: GroupAppKey) {
    return applications.find((app) => app.key === key) || {};
  }
}
