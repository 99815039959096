import React from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { COMPONENT } from 'settings/constants';

import type { IControllerProps } from 'controller/types';

import { Widget } from './Widget';

import classes from './styles.scss';

COMPONENT.name = 'FeedWidget';

function FeedWidget(props: WidgetProps<IControllerProps>) {
  return (
    <SocialGroupsApp {...props} className={classes.root}>
      <Widget />
    </SocialGroupsApp>
  );
}

FeedWidget.displayName = 'FeedWidget';

export default FeedWidget;
