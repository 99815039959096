import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  Info as InfoIcon,
  ErrorSmall as ErrorIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Show } from '../Show';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { Tooltip } from '../Tooltip';

import classes from './FormField.scss';

interface FormFieldProps {
  id: string;
  label?: string;
  required?: boolean;
  error?: string | undefined | false;
  children: React.ReactNode;
  infoContent?: string;
}

export function FormField({
  id,
  label,
  required,
  error,
  children,
  infoContent,
}: FormFieldProps) {
  const { isMobile } = useEnvironment();

  return (
    <Box direction="vertical" gap="8px">
      <Show if={!!label}>
        <Box
          verticalAlign="middle"
          align={isMobile ? 'space-between' : undefined}
        >
          <Typography as="label" htmlFor={id} variant="p2-14">
            {label}
            <Show if={required}>
              <span> *</span>
            </Show>
          </Typography>
          <Show if={!!infoContent}>
            <Tooltip content={infoContent} className={classes.tooltipElement}>
              <InfoIcon size="24px" />
            </Tooltip>
          </Show>
        </Box>
      </Show>

      {children}

      <Show if={!!error}>
        <Typography variant="p2-14" skin="error">
          {' '}
          <ErrorIcon aria-hidden /> {error}
        </Typography>
      </Show>
    </Box>
  );
}

FormField.displayName = 'wui/FormField';
