import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';
import { Button } from 'wui/Button';

export function PendingApprovalDialog(
  props: React.ComponentProps<typeof AlertDialog>,
) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <AlertDialog
      aria-labelledby="pa-dialog-title"
      aria-describedby="pa-dialog-content"
      {...props}
    >
      <DialogTitle
        alert
        id="pa-dialog-title"
        title={t('groups-web.can-not-add-members-popup.title')}
      />
      <DialogContent alert>
        <DialogContentText alert id="pa-dialog-content">
          {t('groups-web.can-not-add-members-popup.subtitle')}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button variant="basic" fullWidth={isMobile} onClick={props.onClose}>
          {t('groups-web.can-not-add-members-popup.back-to-group')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
}

PendingApprovalDialog.displayName = 'PendingApprovalDialog';
