import React from 'react';
import { useSelector } from 'react-redux';
import { groupActionClick } from '@wix/bi-logger-groups/v2';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { useController } from 'common/context/controller';

import {
  selectIsLastAdmin,
  selectHasAdminRole,
  selectIsJoinedGroupMember,
  selectDialogs,
} from 'store/selectors';

import {
  More as MoreIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Notifications as NotificationsIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import LeaveIcon from 'assets/icons/LeaveIcon.svg';
import { Menu, MenuItem } from 'wui/Menu';
import { IconButton } from 'wui/IconButton';

import { GroupSettings } from './GroupSettings';
import { NotificationSettings } from './NotificationSettings';

import { DeleteGroupDialog } from './DeleteGroupDialog';
import { GroupAction } from './GroupAction';

import classes from './GroupActions.scss';

interface GroupActionsProps extends TPAComponentProps {
  groupId: string;
}

export function GroupActions(props: GroupActionsProps) {
  const { groupId, ...rest } = props;
  const bi = useBi();
  const { t } = useTranslation();
  const { application$ } = useController();
  const [openedDialog, setOpenedDialog] = React.useState<GroupAction | null>(
    null,
  );

  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const isAdmin = useSelector(selectHasAdminRole(groupId));
  const isLastAdmin = useSelector(selectIsLastAdmin);
  const dialogs = useSelector(selectDialogs);

  if (!isJoined) {
    return null;
  }

  return (
    <>
      <Menu
        {...rest}
        element={
          <IconButton
            icon={<MoreIcon />}
            className={classes.iconButton}
            aria-label={t('groups-web.a11y.more-actions')}
          />
        }
      >
        {isJoined && (
          <MenuItem
            prefixIcon={<NotificationsIcon />}
            onClick={openNotificationSettingsDialog}
            content={t(GroupAction.NOTIFICATION_SETTINGS)}
          />
        )}
        {isAdmin && (
          <MenuItem
            prefixIcon={<SettingsIcon />}
            onClick={openGroupSettingsDialog}
            content={t(GroupAction.GROUP_SETTINGS)}
          />
        )}
        {isJoined && !isLastAdmin && (
          <MenuItem
            prefixIcon={<LeaveIcon />}
            onClick={handleOpenLeaveDialog}
            content={t(GroupAction.LEAVE)}
          />
        )}
        {isAdmin && (
          <MenuItem
            prefixIcon={<DeleteIcon />}
            onClick={openDeleteDialog}
            content={t(GroupAction.DELETE)}
          />
        )}
      </Menu>

      <DeleteGroupDialog
        groupId={groupId}
        onClose={closeDialog}
        isOpen={openedDialog === GroupAction.DELETE}
      />

      <GroupSettings
        groupId={groupId}
        isOpen={dialogs.groupSettings.isOpen as boolean}
        activeTab={dialogs.groupSettings.params?.activeTab}
        biExtra={dialogs.groupSettings.params?.biExtra}
        data-hook="group-settings-drawer"
        onClose={() =>
          application$.closeDialog('groupSettings', {
            activeTab: '',
            biExtra: {},
          })
        }
      />

      <NotificationSettings
        groupId={groupId}
        isOpen={openedDialog === GroupAction.NOTIFICATION_SETTINGS}
        onClose={closeDialog}
      />
    </>
  );

  function openDeleteDialog() {
    setOpenedDialog(GroupAction.DELETE);
    bi.report(
      groupActionClick({
        action: 'delete',
        group_id: groupId,
        origin: 'menu',
      }),
    );
  }

  function handleOpenLeaveDialog() {
    application$.showDialog({
      dialog: 'leaveGroup',
      params: { groupId },
    });

    bi.report(
      groupActionClick({
        action: 'leave',
        group_id: groupId,
        origin: 'menu',
      }),
    );
  }

  function closeDialog() {
    setOpenedDialog(null);
  }

  function openNotificationSettingsDialog() {
    setOpenedDialog(GroupAction.NOTIFICATION_SETTINGS);

    bi.report(
      groupActionClick({
        action: 'notification_settings',
        group_id: groupId,
        origin: 'menu',
      }),
    );
  }

  function openGroupSettingsDialog() {
    application$.showDialog({
      dialog: 'groupSettings',
    });
    bi.report(
      groupActionClick({
        group_id: groupId,
        action: 'group_settings',
        origin: 'menu',
      }),
    );
  }
}

GroupActions.displayName = 'GroupActions';
