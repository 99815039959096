import { useMemo } from 'react';

import {
  pluginGiphyLoadable as pluginGiphy,
  pluginImageLoadable as pluginImage,
  pluginFileUploadLoadable as pluginFileUpload,
  pluginLinkPreviewLoadable as pluginLinkPreview,
  pluginPollLoadable as pluginPoll,
  pluginGalleryLoadable as pluginGallery,
  pluginLinkLoadable as pluginLink,
  pluginVideoLoadable as pluginVideo,
  pluginAudioLoadable as pluginAudio,
  pluginHtmlLoadable as pluginHtml,
  pluginMentionsLoadable as pluginMentions,
  pluginVerticalEmbedLoadable as pluginVerticalEmbed,
  pluginDividerLoadable as pluginDivider,
  pluginLinkButtonLoadable as pluginLinkButton,
} from '@wix/ricos/loadable';

import {
  pluginEmojiViewer as pluginEmoji,
  pluginTextColorViewer as pluginTextColor,
  pluginTextHighlightViewer as pluginTextHighlight,
  initSpoilersContentState,
  pluginSpoilerViewer as pluginSpoiler,
  SpoilerViewerWrapper,
  pluginLineSpacingViewer as pluginLineSpacing,
} from '@wix/ricos';

import '@wix/ricos/css/plugin-spoiler-viewer.global.css';

import { useMentions } from './useMentions';
import { usePolls } from './usePolls';

export function useViewerPlugins(groupId: string) {
  const pollsHelpers = usePolls(groupId);
  const mentionsHelpers = useMentions(groupId);

  return useMemo(
    () => [
      pluginImage(),
      pluginGiphy(),
      pluginFileUpload(),
      pluginEmoji(),
      pluginMentions(mentionsHelpers),
      pluginLink(),
      pluginLinkPreview(),
      pluginVerticalEmbed(),
      pluginDivider(),
      pluginHtml(),
      pluginTextColor(),
      pluginTextHighlight(),
      pluginLinkButton(),
      pluginPoll({
        ...pollsHelpers,
        voteRole: 'SITE_MEMBERS',
        showVoteRoleSetting: false,
      }),
      pluginGallery(),
      pluginVideo(),
      pluginAudio(),
      pluginSpoiler({
        SpoilerViewerWrapper,
        initSpoilersContentState,
      }),
      pluginLineSpacing(),
    ],
    [groupId],
  );
}
