import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Show } from 'wui/Show';
import { ListItem } from 'wui/ListItem';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemAvatar } from 'wui/ListItemAvatar';
import { ListItemText } from 'wui/ListItemText';
import { Skeleton } from 'wui/Skeleton';

interface IProps
  extends Omit<React.ComponentProps<typeof ListItem>, 'children'> {
  withIcon?: boolean;
}

export const ProfileListItemSkeleton: React.FC<IProps> = (props) => {
  const { withIcon, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <ListItem {...rest}>
      <Show if={props.withIcon}>
        <ListItemIcon />
      </Show>
      <ListItemAvatar>
        <Skeleton variant="circular" width={36} height={36} />
      </ListItemAvatar>
      <ListItemText title={<Skeleton width={isMobile ? '80%' : '45%'} />} />
    </ListItem>
  );
};

ProfileListItemSkeleton.displayName = 'ProfileListItemSkeleton';
