import React from 'react';

import {
  Avatar as WUTAvatar,
  AvatarProps,
  AvatarSize,
} from 'wix-ui-tpa/cssVars';

interface IProps extends Omit<AvatarProps, 'size'> {
  size?: keyof typeof AvatarSize;
}

export function Avatar(props: IProps) {
  const { size, ...rest } = props;

  return <WUTAvatar size={size as AvatarSize} {...rest} />;
}

Avatar.displayName = 'wui/Avatar';
