import loadable from '@wix/yoshi-flow-editor/loadable';

export const RicosViewer = loadable(
  () => import(/* webpackChunkName: "RicosViewer"*/ './RicosViewer'),
  {
    resolveComponent: (component) => component.RicosViewer,
  },
);

export const RicosEditor = loadable(
  () => import(/* webpackChunkName: "RicosEditor"*/ './RicosEditor'),
  {
    resolveComponent: (component) => component.RicosEditor,
  },
);
