import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Stack } from 'wui/Stack';
import { InputDialog } from 'wui/InputDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';
import { Popover } from 'wui/Popover';
import { ToggleButton } from 'wui/ToggleButton';
import {
  PlusSmall as PlusIcon,
  Reaction as ReactionIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Emoji } from '../Emoji';
import { EmojiPicker } from '../../../EmojiPicker/loadable';

import classes from './ReactionsSelect.scss';

interface IProps {
  'data-hook'?: string;

  onSelect(code: string): void;
}

export function ReactionsSelect(props: IProps) {
  const { 'data-hook': dataHook } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [isOpened, setIsIOpened] = React.useState(false);

  const AddReaction = (
    <ToggleButton
      onClick={openPicker}
      className={classes.toggleButton}
      data-hook="reactions-picker-button"
      aria-label={t('groups-web.a11y.reactions.more')}
      aria-haspopup="true"
      aria-expanded={isOpened}
    >
      <Stack
        gap="SP0"
        align="center"
        verticalAlign="middle"
        separator={<span>&nbsp;</span>}
      >
        <PlusIcon size={16} />
        <Emoji>
          <ReactionIcon size={16} />
        </Emoji>
      </Stack>
    </ToggleButton>
  );

  if (isMobile) {
    return (
      <>
        <div data-hook={dataHook} data-is-mobile="true">
          {AddReaction}
        </div>

        <InputDialog
          isOpen={isOpened}
          onClose={closePicker}
          data-hook="reactions-picker-mobile-dialog"
        >
          <DialogTitle />
          <DialogContent disableSideGutters>
            <EmojiPicker
              width="100%"
              height="100%"
              onSelect={handleSelect}
              fallback={<EmptyState variant="section" title={<Spinner />} />}
            />
          </DialogContent>
        </InputDialog>
      </>
    );
  }

  return (
    <Popover
      placement="top"
      maxWidth={500}
      isOpen={isOpened}
      onClose={closePicker}
      data-hook={dataHook}
      focusTrap={false}
      content={
        <EmojiPicker
          focusTrap
          autoFocusSearch
          onSelect={handleSelect}
          fallback={<EmptyState variant="section" title={<Spinner />} />}
        />
      }
    >
      {AddReaction}
    </Popover>
  );

  function handleSelect(code: string) {
    props.onSelect(code);
    closePicker();
  }

  function closePicker() {
    setIsIOpened(false);
  }

  function openPicker() {
    setIsIOpened(true);
  }
}

ReactionsSelect.displayName = 'ReactionsSelect';
