import React from 'react';

import type { IDraftJSActivity } from '@wix/social-groups-serverless/dist/feed/types';

import { useRichContent } from 'common/utils/ricos';
import { RicosViewer } from 'common/components/Ricos/RicosViewer';

import { GROUPS_APP_DEFINITION_ID } from '../../../../../constants';

import { IActivityContentProps } from './types';

export function RichContentActivity(props: IActivityContentProps) {
  const { item } = props;

  const feedItemId = item.feedItemId as string;
  const data = item?.activity?.data as IDraftJSActivity;
  const groupId = item.applicationContext?.contextId as string;

  const content = useRichContent(
    replaceNewlinesWithSpace(data.content, data.activity === 'event_added'), // if there is a new line in description, event plugin is broken
  );

  return (
    <RicosViewer
      usage="FeedItem"
      content={content}
      postId={feedItemId}
      containerId={GROUPS_APP_DEFINITION_ID}
      groupId={groupId}
      contentId={item.feedItemId}
    />
  );

  function replaceNewlinesWithSpace(
    input: string,
    shouldReplace: boolean,
  ): string {
    if (shouldReplace) {
      return input.replace(/\n/g, ' ');
    }
    return input;
  }
}

RichContentActivity.displayName = 'RichContentActivity';
