import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { clamp } from 'lodash';
import cls from 'classnames';

import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';

import classes from './EditableText.scss';

interface EditableTextProps {
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  maxLength?: number;
  autofocus?: boolean;
  className?: string;
}

export const EditableText: React.FC<EditableTextProps> = ({
  value,
  onChange,
  placeholder,
  maxLength,
  className,
  autofocus,
}) => {
  const $el = useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState(1);

  useEffect(() => {
    handleWindowResizeEvent();
    window.addEventListener('resize', handleWindowResizeEvent);
    return () => window.removeEventListener('resize', handleWindowResizeEvent);
  }, []);

  useLayoutEffect(() => {
    if (autofocus) {
      handleAutofocus();
    }
  }, [autofocus]);

  return (
    <Box className={classes.root} data-hook="editable-text">
      <textarea
        value={value}
        ref={$el}
        className={cls(classes.input, className)}
        placeholder={placeholder}
        rows={rows}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        maxLength={maxLength}
      />
      <Show if={!!maxLength}>
        <Box className={classes.charsLeftCount}>
          <Typography
            secondary
            aria-live="polite"
            aria-atomic="true"
            variant="p2-12"
          >
            {maxLength! - (value?.length || 0)}
          </Typography>
        </Box>
      </Show>
    </Box>
  );

  function handleAutofocus() {
    setTimeout(() => {
      $el.current?.focus();
      $el.current?.setSelectionRange(-1, -1);
    }, 0);
  }

  function handleWindowResizeEvent() {
    setTimeout(() => resize());
  }

  function handleOnChange(evt: React.ChangeEvent<HTMLTextAreaElement>) {
    onChange?.(evt.target.value);
    resize();
  }

  function handleOnKeyDown(evt: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (evt.keyCode === 13) {
      evt.preventDefault();
    }
  }

  function resize() {
    if (!$el.current) {
      return;
    }

    const computedStyles = getComputedStyle($el.current);

    const lineHeight = Math.ceil(parseFloat(computedStyles.lineHeight));
    const paddingTop = parseInt(computedStyles.paddingTop, 10);
    const paddingBottom = parseInt(computedStyles.paddingBottom, 10);

    $el.current.rows = 1;

    const { scrollHeight } = $el.current;

    $el.current.rows = rows;

    const contentHeight = scrollHeight - (paddingTop + paddingBottom);
    const updatedRows = Math.ceil(
      clamp(contentHeight / lineHeight, 1, Infinity),
    );

    setRows(updatedRows);
  }
};

EditableText.displayName = 'wui/EditableText';
