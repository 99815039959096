import { useErrorMonitor } from '@wix/yoshi-flow-editor';

import { getUploadUrl } from '@wix/social-groups-serverless/dist/media-manager/http';
import { IUploadResponse } from '@wix/social-groups-serverless/dist/media-manager/types';

import { useHttpClient } from './useHttpClient';

export function useFileUploader() {
  const errorMonitor = useErrorMonitor();
  const httpClient = useHttpClient();

  return {
    async upload(file: File) {
      const { data: url } = await httpClient.request(
        getUploadUrl({
          name: file.name,
          type: file.type,
          size: file.size,
        }),
      );

      const data = new FormData();
      data.append('file', file, file.name);

      try {
        const response = await httpClient.post<IUploadResponse[]>(url, data, {
          headers: { 'Content-Type': 'multipart/form-data;' },
        });

        return response.data[0];
      } catch (error) {
        errorMonitor.captureException(error as Error);
        console.error(error);
        return Promise.reject(error);
      }
    },
  };
}
