import {
  wixColorParam,
  StyleParamType,
  createStylesParam,
} from '@wix/tpa-settings';

export function getBackgroundColorParam() {
  return createStylesParam('background-color-param', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  });
}

export function getRandomThemeColorParam() {
  return createStylesParam('random-color-param', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(`color-${getRandomInRange(6, 50)}`, 0.2),
  });
}

function getRandomInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
