import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';
import { Stack } from 'wui/Stack';
import { Hide } from 'wui/Hide';

export function FeedItemSkeleton() {
  const { isMobile } = useEnvironment();

  return (
    <Card sideBorders={!isMobile}>
      <CardHeader
        title={<Skeleton width={isMobile ? '60%' : '30%'} />}
        subtitle={<Skeleton width={isMobile ? '50%' : '25%'} />}
        avatar={
          <Skeleton
            variant="circular"
            width={isMobile ? 36 : 52}
            height={isMobile ? 36 : 52}
          />
        }
      />

      <CardContent>
        <Skeleton variant="rect" height={200} />
      </CardContent>

      <CardContent>
        <Stack gap="SP2">
          <Skeleton variant="rounded" width={50} height={30} />
          <Skeleton variant="rounded" width={50} height={30} />
          <Skeleton variant="rounded" width={50} height={30} />
          <Hide if={isMobile}>
            <Skeleton variant="rounded" width={50} height={30} />
            <Skeleton variant="rounded" width={50} height={30} />
          </Hide>
        </Stack>
      </CardContent>
    </Card>
  );
}

FeedItemSkeleton.displayName = 'FeedItemSkeleton';
