import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { vars } from 'settings/helpers';
import * as common from 'settings/common';
import * as wui from 'settings/wui';
import * as feed from 'settings/feed';
import * as group from 'settings/group';
import * as convertors from 'settings/convertors';

export default {
  ...common.styles,
  ...wui.styles,
  ...feed.styles,
  ...group.layout.styles,
  ...group.header.styles,

  showComments: createStylesParam('showComments', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showFeedWidgetTitle: createStylesParam('showFeedWidgetTitle', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  feedWidgetTitleColor: createStylesParam('feedWidgetTitleColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  feedWidgetTitleFont: createStylesParam('feedWidgetTitleFont', {
    type: StyleParamType.Font,
    getDefaultValue: (params) =>
      wixFontParam('Title', {
        size: params.isMobile ? 16 : 32,
        htmlTag: 'h1',
      })(params),
  }),
};

export const customCssVars = vars(({ styleParams: { booleans } }) => {
  return {
    'comments-display': convertors.display(booleans.showComments),
    'reactions-visibility': convertors.visibility(booleans.showReactions),
    'feed-widget-title-display': convertors.display(
      booleans.showFeedWidgetTitle,
    ),
  };
});
