import React from 'react';
import cls from 'classnames';

import { Tag as TagTPA, TagSize, TagSkin } from 'wix-ui-tpa/cssVars';

import classes from './Tag.scss';

interface IProps
  extends Omit<React.ComponentProps<typeof TagTPA>, 'size' | 'skin'> {
  size?: 'small' | 'medium' | 'large';
  skin?: 'light' | 'solid';
  ellipsis?: boolean;
}

export function Tag(props: IProps) {
  const { size, skin, className, ellipsis, ...rest } = props;

  return (
    <TagTPA
      size={size as TagSize}
      skin={skin as TagSkin}
      className={cls(classes.root, className, {
        [classes.ellipsis]: ellipsis,
      })}
      {...rest}
    />
  );
}

Tag.displayName = 'wui/Tag';
