import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { groupFeedTopicsAddTopicClickIntent } from '@wix/bi-logger-groups/v2';

import { useDidUpdate } from 'common/hooks';
import { selectTopicsStatus } from 'store/selectors';
import { useController } from 'common/context/controller';

import { Drawer } from 'wui/Drawer';
import { Button } from 'wui/Button';
import { TextField } from 'wui/TextField';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogFooter } from 'wui/DialogFooter';
import { DialogContent } from 'wui/DialogContent';
import { DialogContainer } from 'wui/DialogContainer';

interface ICreateTopicForm {
  displayName: string;
}

interface IProps extends React.ComponentProps<typeof Drawer> {
  groupId: string;
}

export function CreateTopicDialog(props: IProps) {
  const { groupId, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { topics$ } = useController();

  const { create } = useSelector(selectTopicsStatus);

  const form = useFormik<ICreateTopicForm>({
    onSubmit: handleSubmit,
    initialValues: {
      displayName: '',
    },
    validationSchema: yup.object().shape({
      displayName: yup
        .string()
        .trim()
        .required(
          t('groups-web.discussion.create-topic.dialog.name.error.empty'),
        ),
    }),
  });

  useEffect(() => {
    if (!props.isOpen) {
      form.resetForm();
    }
  }, [props.isOpen]);

  useDidUpdate(() => {
    if (!create.pending) {
      props.onClose();
      form.setSubmitting(false);
    }
  }, [create.pending]);

  return (
    <Drawer
      anchor="right"
      backdrop={false}
      hideCloseButton={false}
      aria-labelledby="ct-dialog-title"
      {...rest}
    >
      <DialogContainer>
        <DialogTitle
          id="ct-dialog-title"
          title={t('groups-web.discussion.create-topic.dialog.title')}
        />
        <DialogContent>
          <TextField
            showCharCount
            maxLength={20}
            label={t('groups-web.discussion.create-topic.dialog.name.label')}
            placeholder={t(
              'groups-web.discussion.create-topic.dialog.name.placeholder',
            )}
            {...form.getFieldMeta('displayName')}
            {...form.getFieldProps('displayName')}
          />
        </DialogContent>
        <DialogFooter>
          <Button
            outlined
            fullWidth
            variant="basic"
            onClick={form.submitForm}
            loading={form.isSubmitting}
            disabled={!form.isValid || form.isSubmitting}
          >
            {t('groups-web.discussion.create-topic.dialog.submit')}
          </Button>
        </DialogFooter>
      </DialogContainer>
    </Drawer>
  );

  function handleSubmit(values: ICreateTopicForm) {
    form.setSubmitting(true);

    topics$.create(groupId, values.displayName.trim());

    bi.report(
      groupFeedTopicsAddTopicClickIntent({
        groupId,
        origin: 'topics_list_plus_btn_in_post',
      }),
    );
  }
}

CreateTopicDialog.displayName = 'CreateTopicDialog';
