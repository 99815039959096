import React from 'react';
import cls from 'classnames';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { SwitchTransition } from 'react-transition-group';

import {
  Camera as CameraIcon,
  Delete as DeleteIcon,
  Replace as ReplaceIcon,
  Settings as SettingsIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { TPAComponentProps } from 'wui/types';
import { Button } from 'wui/Button';
import { Fade } from 'wui/Fade';
import { Show } from 'wui/Show';
import { Media } from 'wui/Media';
import { Stack } from 'wui/Stack';
import { IconButton, IconButtonThemes } from 'wui/IconButton';

import { type EditableImage } from './types';
import { getPreview } from './helpers';

import { ImageSettingsDialog } from './ImageSettingsDialog';

import classes from './ImageViewer.scss';

interface IProps extends TPAComponentProps {
  wired?: boolean;
  value: EditableImage;
  label?: string;
  onChange(value?: EditableImage): void;
  onBlur?(e: React.FocusEvent): void;
  withSettings?: boolean;
}

export function ImageViewer({
  wired,
  value,
  label,
  onChange,
  onBlur,
  withSettings,
  ...rest
}: IProps) {
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  const $input = React.useRef<HTMLInputElement>(null);
  const $imageActions = React.useRef<HTMLDivElement>(null);
  const $container = React.useRef<HTMLDivElement>(null);

  const [media, setMedia] = React.useState(getPreview(value));
  const [isImageSettingsOpened, setIsImageSettingsOpened] =
    React.useState(false);

  const canAddAltText = experiments.enabled(
    'specs.groups.AllowToAddImageAltText',
  );

  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    if (isActive) {
      $imageActions.current?.querySelector('button')?.focus();
    }
  }, [isActive]);

  return (
    <div
      ref={$container}
      className={cls(classes.root, {
        [classes.mobile]: isMobile,
        [classes.wired]: wired,
        [classes.hasImage]: !!media,
        [classes.active]: isActive,
      })}
      data-empty={!media}
      tabIndex={isActive ? -1 : 0}
      role="region"
      onFocus={(e) => {
        if (e.target === $container.current) {
          setIsActive(true);
        }
      }}
      onBlur={(e) => {
        if (
          // ignore blur events within container
          $container.current?.contains(e.relatedTarget) ||
          isImageSettingsOpened
        ) {
          return;
        }
        setIsActive(false);
      }}
      {...rest}
    >
      <SwitchTransition>
        {(() => {
          if (!media) {
            return (
              <Fade key="empty">
                <Button
                  autoFocus
                  outlined
                  variant={wired ? 'primary' : 'basic'}
                  data-hook="upload-image-button"
                  onClick={handleUploadClick}
                  prefixIcon={<CameraIcon />}
                  size={isMobile ? 'tiny' : 'medium'}
                  aria-label={label}
                >
                  {label}
                </Button>
              </Fade>
            );
          }

          return (
            <Fade key={media}>
              <>
                <Media
                  fluid
                  src={media as string}
                  className={classes.image}
                  resize="cover"
                  wired
                  alt={value?.altText!}
                />
                <Stack
                  className={classes.actions}
                  ref={$imageActions}
                  gap="SP2"
                  aria-label={t(
                    'group-web.image-viewer.actions.a11y.toolbar.label',
                  )}
                >
                  <Show if={canAddAltText && withSettings}>
                    <IconButton
                      theme={IconButtonThemes.Box}
                      data-hook="settings-image-button"
                      icon={<SettingsIcon />}
                      onClick={openImageSettings}
                      aria-label={t(
                        'group-web.image-viewer.actions.a11y.settings.label',
                      )}
                    />
                  </Show>
                  <IconButton
                    theme={IconButtonThemes.Box}
                    data-hook="replace-image-button"
                    icon={<ReplaceIcon />}
                    onClick={handleUploadClick}
                    aria-label={t(
                      'group-web.image-viewer.actions.a11y.replace.label',
                    )}
                  />
                  <IconButton
                    theme={IconButtonThemes.Box}
                    data-hook="delete-image-button"
                    icon={<DeleteIcon />}
                    onClick={handleDeleteClick}
                    aria-label={t(
                      'group-web.image-viewer.actions.a11y.delete.label',
                    )}
                  />
                </Stack>
              </>
            </Fade>
          );
        })()}
      </SwitchTransition>

      <input
        hidden
        accept="image/*"
        data-hook="upload-input"
        type="file"
        ref={$input}
        onChange={handleFileInputChange}
      />

      <ImageSettingsDialog
        isOpen={isImageSettingsOpened}
        onClose={closeImageSettings}
        altText={value?.altText || ''}
        onAltTextChange={handleAltTextChange}
        src={media as string}
      />
    </div>
  );

  function closeImageSettings() {
    setIsImageSettingsOpened(false);
  }

  function openImageSettings() {
    setIsImageSettingsOpened(true);
  }

  function handleDeleteClick() {
    onChange({});
    setMedia(undefined);
  }

  function handleUploadClick() {
    $input.current?.click();
  }

  function handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [file] = Array.from(event.target.files!);

    if (media) {
      URL.revokeObjectURL(media);
    }

    setMedia(URL.createObjectURL(file));

    event.target.value = null as any;
    onChange({ ...value, image: file, altText: undefined });
  }

  function handleAltTextChange(newAltText: string) {
    onChange({ ...value, altText: newAltText });
  }
}

ImageViewer.displayName = 'wui/ImageViewer';
