import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  AccessRestrictionType,
  IFeedItem,
} from '@wix/social-groups-serverless/dist/feed/types';
import { selectIsJoinedGroupMember } from 'store/selectors';
import { useController } from 'common/context/controller';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { Stack } from 'wui/Stack';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Collapse } from 'wui/Collapse';
import { Divider } from 'wui/Divider';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';
import { Typography } from 'wui/Typography';

import { Comments } from './Comments/loadable';
import { Reactions } from './Reactions';
import { FeedItemActions } from './FeedItemActions';
import { FeedItemHeader } from './FeedItemHeader';
import { FeedItemContent } from './FeedItemContent';
import { Disclaimer } from './Disclaimer';
import { FeedItemPreview } from './FeedItemPreview';
import { FeedItemTopics } from './FeedItemTopics';
import { FeedItemStats } from './FeedItemStats';

import { getDescribedBy, getLabelledBy } from './a11y';

import classes from './FeedItem.scss';

interface IProps extends React.ComponentProps<typeof Card> {
  focused?: boolean;
  item: IFeedItem;
  truncate?: boolean;
  promote?: boolean;
  commentsInitiallyExpaned?: boolean;
  disabledComments?: boolean;
  origin?: 'GroupFeed' | 'CentralFeed';
}

export const FeedItem = React.memo((props: IProps) => {
  const {
    item,
    truncate,
    promote,
    focused,
    commentsInitiallyExpaned,
    disabledComments,
    origin,
    ...rest
  } = props;

  const { isMobile } = useEnvironment();
  const { comments$ } = useController();

  const ref = React.useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(
    commentsInitiallyExpaned ?? !isMobile,
  );

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));

  const canViewFullPost = item.permissions?.canViewFullPost ?? false;
  const hasStats = item.reactions?.total > 0 || item.viewsCount! > 0;
  const suggestToJoin = !isJoined && promote;
  const isRestrictedByPP =
    item.accessRestriction?.type === AccessRestrictionType.PAID_PLANS;

  React.useEffect(() => {
    if (focused) {
      ref.current?.focus({ preventScroll: true });
    }
  }, [focused, ref.current]);

  return (
    <Card
      ref={ref}
      role="article"
      tabIndex={0}
      className={classes.root}
      sideBorders={!isMobile}
      aria-labelledby={getLabelledBy(feedItemId)}
      aria-describedby={getDescribedBy(feedItemId)}
      {...rest}
    >
      <Stack
        gap="SP3"
        direction="vertical"
        separator={<Divider inset aria-hidden="true" />}
      >
        <Show
          if={Boolean(item.pin) || Boolean(suggestToJoin) || isRestrictedByPP}
        >
          <Disclaimer
            item={item}
            data-hook="feed-item-disclaimer"
            isPinned={Boolean(item.pin)}
            isSuggested={Boolean(suggestToJoin)}
            isRestrictedByPP={isRestrictedByPP}
          />
        </Show>
        <FeedItemHeader
          item={item}
          promote={promote}
          data-hook="feed-item-header"
          action={
            <FeedItemActions
              origin={origin}
              item={item}
              data-hook="feed-item-actions"
            />
          }
        />
      </Stack>

      <Show if={!!item.entity.title}>
        <CardContent>
          <Typography
            as="h3"
            className={classes.title}
            data-hook="feed-item-title"
          >
            {item.entity.title}
          </Typography>
        </CardContent>
      </Show>

      <Show if={Boolean(item.permissions?.canViewFullPost)}>
        <FeedItemContent
          item={item}
          truncate={truncate}
          data-hook="feed-item-content"
        />
      </Show>

      <Hide if={Boolean(item.permissions?.canViewFullPost)}>
        <FeedItemPreview item={item} isSuggested={Boolean(suggestToJoin)} />
      </Hide>

      <FeedItemTopics item={item} limit={isMobile ? 2 : 5} />

      <CardContent
        direction="horizontal"
        align="space-between"
        verticalAlign="middle"
      >
        <Reactions
          item={item}
          data-hook="feed-item-reactions"
          onReact={item.permissions?.canAddComment ? openCommentBox : undefined}
        />

        <Hide if={hasStats}>
          <FeedItemStats
            short
            item={item}
            data-hook="feed-item-stats"
            onCommentsClick={canViewFullPost ? handleToggleComments : undefined}
          />
        </Hide>
      </CardContent>

      <Show if={hasStats}>
        <CardContent>
          <FeedItemStats
            item={item}
            data-hook="feed-item-stats"
            onCommentsClick={canViewFullPost ? handleToggleComments : undefined}
          />
        </CardContent>
      </Show>

      <Show if={!disabledComments && item.permissions?.canViewFullPost}>
        <Collapse isOpened={expanded} unmountOnExit>
          <Comments
            item={item}
            auto={isMobile}
            fallback={<EmptyState variant="section" title={<Spinner />} />}
          />
        </Collapse>
      </Show>
    </Card>
  );

  function openCommentBox() {
    if (expanded || disabledComments) {
      return;
    }

    setExpanded(true);
    comments$.openCommentBox(feedItemId, {
      shouldFocus: true,
      shouldScroll: true,
    });
  }

  function handleToggleComments() {
    if (disabledComments) {
      return;
    }

    setExpanded((expanded) => !expanded);
  }
});

FeedItem.displayName = 'FeedItem';
