import React from 'react';
import cls from 'classnames';

import {
  type SectionNotificationType,
  type SectionNotificationSize,
  type SectionNotificationProps,
  SectionNotification as TPASectionNotification,
} from 'wix-ui-tpa/cssVars';

import classes from './SectionNotification.scss';

export interface ISectionNotificationProps extends SectionNotificationProps {
  type?: keyof typeof SectionNotificationType;
  size?: keyof typeof SectionNotificationSize;
}

export function SectionNotification(props: ISectionNotificationProps) {
  const { className, ...rest } = props;

  return (
    <TPASectionNotification
      {...rest}
      className={cls(classes.root, className)}
    />
  );
}

SectionNotification.displayName = 'SectionNotification';

SectionNotification.Text = TPASectionNotification.Text;
SectionNotification.Icon = TPASectionNotification.Icon;
SectionNotification.Button = TPASectionNotification.Button;
