import React from 'react';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Divider } from 'wui/Divider';
import { Typography } from 'wui/Typography';
import { TextButton } from 'wui/TextButton';
import { DialogContent } from 'wui/DialogContent';
import { memberPermissionsEditClickSrc111Evid530 } from '@wix/bi-logger-groups-data/v2';

import { MemberPermissionsDialog } from './MemberPermissionsDialog';
import { GroupQuestionsDialog } from './GroupQuestionsDialog';
import { EDIT_QUESTIONS_LINK } from './dataHooks';

interface IModerateTabProps {
  groupId: string;
}

export function ModerateTab(props: IModerateTabProps) {
  const { t } = useTranslation();
  const bi = useBi();

  const [dialogs, setDialogs] = React.useState({
    permissions: false,
    questions: false,
  });

  return (
    <DialogContent>
      <Box direction="vertical" gap="SP4">
        <Box direction="vertical" as="section">
          <Typography as="h3">
            {t('groups-web.group.settings.moderate.permissions.title')}
          </Typography>
          <Typography secondary>
            {t('groups-web.group.settings.moderate.permissions.caption')}
          </Typography>
          <TextButton variant="link" onClick={handleDialogOpen('permissions')}>
            {t('groups-web.group.settings.moderate.permissions.edit')}
          </TextButton>
        </Box>

        <Divider bw />

        <Box direction="vertical" as="section">
          <Typography as="h3">
            {t('groups-web.group.settings.moderate.questions.title')}
          </Typography>
          <Typography secondary>
            {t('groups-web.group.settings.moderate.questions.caption')}
          </Typography>
          <TextButton
            variant="link"
            data-hook={EDIT_QUESTIONS_LINK}
            onClick={handleDialogOpen('questions')}
          >
            {t('groups-web.group.settings.moderate.questions.add')}
          </TextButton>
        </Box>
      </Box>

      <MemberPermissionsDialog
        hideBackdrop
        groupId={props.groupId}
        isOpen={dialogs.permissions}
        onClose={handleDialogClose('permissions')}
      />

      <GroupQuestionsDialog
        hideBackdrop
        groupId={props.groupId}
        isOpen={dialogs.questions}
        onClose={handleDialogClose('questions')}
      />
    </DialogContent>
  );

  function handleDialogOpen(dialog: keyof typeof dialogs) {
    return () => {
      setDialogs((dialogs) => ({ ...dialogs, [dialog]: true }));
      bi.report(
        memberPermissionsEditClickSrc111Evid530({
          setting_name: dialog,
        }),
      );
    };
  }

  function handleDialogClose(dialog: keyof typeof dialogs) {
    return () => setDialogs((prev) => ({ ...prev, [dialog]: false }));
  }
}

ModerateTab.displayName = 'ModerateTab';
