import React from 'react';
import cls from 'classnames';
import { type TPAComponentProps } from 'wui/types';

import classes from './Emoji.scss';

interface IEmojiProps extends TPAComponentProps {
  children: React.ReactNode;
}

export function Emoji(props: IEmojiProps) {
  const { children, className, ...rest } = props;

  return (
    <span role="img" className={cls(classes.root, className)} {...rest}>
      {children}
    </span>
  );
}

Emoji.displayName = 'Emoji';
