import React from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type {
  IGroupMember,
  IMember,
} from '@wix/social-groups-serverless/dist/members/types';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { ListItem } from 'wui/ListItem';
import { Checkbox } from 'wui/Checkbox';
import { ListItemAvatar } from 'wui/ListItemAvatar';
import { ListItemAction } from 'wui/ListItemAction';
import { ListItemText } from 'wui/ListItemText';
import { ListItemButton } from 'wui/ListItemButton';
import { Typography } from 'wui/Typography';
import { Avatar } from 'wui/Avatar';

import { RoleIcon } from '../RoleIcon';
import { ProfileLink } from '../ProfileLink';
import { MemberBadges } from '../MemberBadges';

import classes from './ProfileListItem.scss';

interface IProps extends Partial<React.ComponentProps<typeof ListItem>> {
  wired?: boolean;
  selected?: boolean;
  profile?: IMember | IGroupMember;
  children?: React.ReactNode;
  subtitle?: React.ReactNode;

  avatarProps?: Partial<React.ComponentProps<typeof Avatar>>;

  onClick?(event: React.MouseEvent | React.ChangeEvent): void;
}

export function ProfileListItem(props: IProps) {
  const {
    wired,
    profile,
    children,
    subtitle,
    avatarProps,
    selected,
    onClick,
    className,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  if (onClick) {
    return (
      <ListItemButton
        data-is-button="true"
        onClick={onClick}
        data-name={profile?.name}
        className={cls(classes.root, className, { [classes.wired]: wired })}
        {...rest}
      >
        <Show if={selected !== undefined}>
          <ListItemAction>
            <Checkbox checked={selected} onChange={onClick} />
          </ListItemAction>
        </Show>
        <ListItemAvatar>
          <Avatar
            lettersLimit={2}
            name={profile?.name}
            src={profile?.imageUrl}
            {...avatarProps}
          />
        </ListItemAvatar>
        <ListItemText
          title={
            <Typography
              noWrap
              as="div"
              variant={isMobile ? 'p2-14' : 'p2-16'}
              aria-describedby={profile?.memberId + '_role'}
              data-hook="profile-name"
            >
              {profile?.name || t('groups-web.member.anonymous')}
              <RoleIcon
                id={profile?.memberId + '_role'}
                role={(profile as IGroupMember)?.role}
                data-hook="profile-role"
              />
            </Typography>
          }
          subtitle={
            subtitle !== undefined ? (
              subtitle
            ) : (
              <MemberBadges member={profile} />
            )
          }
        />
        {children ? <ListItemAction>{children}</ListItemAction> : null}
      </ListItemButton>
    );
  }

  return (
    <ListItem
      data-is-button="false"
      className={cls(classes.root, className, { [classes.wired]: wired })}
      {...rest}
    >
      <ListItemAvatar>
        <ProfileLink profile={profile} tabIndex={-1}>
          <Avatar
            lettersLimit={2}
            name={profile?.name}
            src={profile?.imageUrl}
            {...avatarProps}
          />
        </ProfileLink>
      </ListItemAvatar>
      <ListItemText
        title={
          <Box gap="SP0" verticalAlign="middle" className={classes.title}>
            <ProfileLink
              noWrap
              profile={profile}
              className={classes.name}
              data-hook="profile-name"
            />
            <RoleIcon
              role={(profile as IGroupMember)?.role}
              data-hook="profile-role"
            />
          </Box>
        }
        subtitle={
          subtitle !== undefined ? subtitle : <MemberBadges member={profile} />
        }
      />
      {children ? <ListItemAction>{children}</ListItemAction> : null}
    </ListItem>
  );
}

ProfileListItem.displayName = 'ProfileListItem';
