import React from 'react';
import {
  RadioButton as TPARadioButton,
  RadioButtonProps,
  RadioButtonTheme,
} from 'wix-ui-tpa/cssVars';

export interface IRadioButtonProps extends Omit<RadioButtonProps, 'theme'> {
  theme?: keyof typeof RadioButtonTheme;
}

export function RadioButton(props: IRadioButtonProps) {
  const { className, theme, ...rest } = props;

  return (
    <TPARadioButton
      theme={theme as RadioButtonTheme}
      className={className}
      {...rest}
    />
  );
}

RadioButton.displayName = 'wui/RadioButton';
