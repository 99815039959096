import React from 'react';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';

interface RceEditorFallbackProps {
  bw?: boolean;
  wired?: boolean;
}

export const RceEditorFallback: React.FC<RceEditorFallbackProps> = (props) => (
  <EmptyState
    {...props}
    style={{ minHeight: '248px' }}
    variant="section"
    title={<Spinner />}
  />
);

RceEditorFallback.displayName = 'RceEditorFallback';
