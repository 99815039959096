import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';

export function FeedItemSearchResultSkeleton() {
  const { isMobile } = useEnvironment();

  return (
    <Card bw sideBorders={false} topBottomBorders={false}>
      <CardHeader
        title={<Skeleton width={isMobile ? '60%' : '30%'} />}
        subtitle={<Skeleton width={isMobile ? '50%' : '25%'} />}
        avatar={
          <Skeleton
            variant="circular"
            width={isMobile ? 36 : 52}
            height={isMobile ? 36 : 52}
          />
        }
      />

      <CardContent>
        <Skeleton variant="rect" height={72} />
      </CardContent>

      <CardContent>
        <Skeleton variant="rect" width={150} height={24} />
      </CardContent>
    </Card>
  );
}

FeedItemSearchResultSkeleton.displayName = 'FeedItemSearchResultSkeleton';
